import React from "react"
import {
    Button,
    Datagrid,
    DateField,
    Filter,
    List,
    NumberField,
    NumberInput,
    SelectInput,
    TextField,
    TextInput,
    useDataProvider,
    useNotify,
    useRecordContext,
    useRedirect,
} from "react-admin"
import { useMutation } from "react-query"
import ChipStatus from "../components/ChipStatus"

const CompanyFilter = props => (
    <Filter {...props}>
        <TextInput alwaysOn label="Search by name" source="name" />
        <NumberInput alwaysOn label="Search by ID" source="id" />
        <TextInput alwaysOn label="Search by UUID" source="uuid" />
        <SelectInput
            source="status"
            choices={[
                { id: "ACTIVE", name: "Active" },
                { id: "DISABLED", name: "Disabled" },
                { id: "ENTERPRISE", name: "Enterprise" },
                { id: "FREE", name: "Free" },
                { id: "LIFETIME_DEAL", name: "Lifetime deal" },
                { id: "PARTNER", name: "Partner" },
                { id: "PAUSED", name: "Paused" },
                { id: "PAYMENT_FAILED", name: "Payment failed" },
                { id: "PENDING", name: "Pending" },
                { id: "TRIAL", name: "Trial" },
                { id: "TRIAL_ENDED", name: "Trial ended" },
                { id: "UNLIMITED", name: "Unlimited" },
            ]}
            alwaysOn
        />
    </Filter>
)

const StatusButton = ({ id, label, action }) => {
    const notify = useNotify()
    const redirect = useRedirect()
    const dataProvider = useDataProvider()

    const { mutate, isLoading } = useMutation(
        () =>
            dataProvider.update("companies", {
                id: id,
                data: {
                    action: action,
                },
            }),
        {
            onSuccess: ({ data }) => {
                redirect("/companies/" + id + "/show")
                notify("Company " + id + " updated")
            },
            onError: error => notify(`Error during change of the company status: ${error.message}`, "warning"),
        }
    )
    return <Button label={label} onClick={mutate} disabled={isLoading} />
}

const CompanyStatusActions = () => {
    const record = useRecordContext()
    return (
        <>
            {["PENDING", "TRIAL", "TRIAL_ENDED", "DISABLED", "FREE"].includes(record.status) && (
                <>
                    <StatusButton id={record.id} label="Mark as Unlimited" action="UNLIMITED" />
                    <br />
                </>
            )}
            {["TRIAL", "TRIAL_ENDED"].includes(record.status) && (
                <>
                    <StatusButton id={record.id} label="Extend Trial (7days)" action="EXTEND" />
                    <br />
                </>
            )}
        </>
    )
}

export const CompanyList = props => (
    <List {...props} perPage={25} exporter={false} filters={<CompanyFilter />} sort={{ field: "id", order: "DESC" }}>
        <Datagrid rowClick="show" bulkActionButtons={false}>
            <TextField source="id" label="ID" />
            <TextField source="name" label="Company Name" />
            <DateField source="created_date" />
            <ChipStatus source="status" />
            <NumberField source="number_of_users" />
            <NumberField source="number_of_templates" />
            <NumberField source="number_of_api_calls" sortable={false} />
            <CompanyStatusActions />
        </Datagrid>
    </List>
)
