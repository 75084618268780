import React from "react"
import TemplateCardField from "./TemplateCardField"
import { useListContext } from "react-admin"
import { Grid } from "@mui/material"

const TemplateGrid = () => {
    const { data, isLoading } = useListContext()

    if (isLoading) {
        return <></>
    }

    return (
        <Grid container spacing={1}>
            {data.map(record => (
                <Grid item key={record.id}>
                    <TemplateCardField key={record.id} record={record} />
                </Grid>
            ))}
        </Grid>
    )
}

export default TemplateGrid
