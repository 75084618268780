import React from "react"
import { Filter, List, SelectInput, TextInput } from "react-admin"
import SemrushBannerGrid from "../../components/SemrushBannerGrid"

const BannerFilter = props => {
    return (
        <Filter {...props}>
            <TextInput source="user_id" label="User ID" alwaysOn />
            <TextInput source="funnel_id" label="Funnel ID" />
            <SelectInput
                source="template_id"
                choices={[
                    { id: 100, name: "100-FB_FEED" },
                    { id: 101, name: "101-FB_FEED" },
                    { id: 102, name: "102-FB_FEED" },
                    { id: 103, name: "103-FB_FEED" },
                    { id: 104, name: "104-FB_FEED" },

                    { id: 110, name: "100-LI_FEED" },
                    { id: 111, name: "111-LI_FEED" },
                    { id: 112, name: "112-LI_FEED" },
                    { id: 113, name: "113-LI_FEED" },
                    { id: 114, name: "114-LI_FEED" },

                    { id: 120, name: "120-TW_DMC" },
                    { id: 121, name: "121-TW_DMC" },
                    { id: 122, name: "122-TW_DMC" },
                    { id: 123, name: "123-TW_DMC" },
                    { id: 124, name: "124-TW_DMC" },

                    { id: 400, name: "400-FB_SQUARE" },
                    { id: 401, name: "401-FB_SQUARE" },
                    { id: 402, name: "402-FB_SQUARE" },
                    { id: 403, name: "403-FB_SQUARE" },
                    { id: 404, name: "404-FB_SQUARE" },

                    { id: 1000, name: "1000-TW_FEED" },
                    { id: 1001, name: "1001-TW_FEED" },
                    { id: 1002, name: "1002-TW_FEED" },
                    { id: 1003, name: "1003-TW_FEED" },
                    { id: 1004, name: "1004-TW_FEED" },
                ]}
            />
            <SelectInput
                source="format_id"
                choices={[
                    { id: 2, name: "FB_FEED" },
                    { id: 3, name: "LI_FEED" },
                    { id: 4, name: "TW_FEED" },
                    { id: 5, name: "TW_DMC" },
                    { id: 8, name: "FB_SQUARE" },
                ]}
                alwaysOn
            />
        </Filter>
    )
}

export const SemrushBannerList = props => (
    <List filters={<BannerFilter />} {...props} exporter={false} bulkActionButtons={false} sort={{ field: "id", order: "DESC" }}>
        <SemrushBannerGrid />
    </List>
)
