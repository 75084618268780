import React, { useEffect, useState } from "react"
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { useDataProvider } from "react-admin"

const TemplateFunnelErrors = ({ id }) => {
    const [errors, setErrors] = useState([])
    const dataProvider = useDataProvider()

    useEffect(() => {
        dataProvider
            .get("/template_funnels/" + id + "/errors")
            .then(results => {
                if (results) {
                    setErrors(results.data)
                }
            })
            .catch(e => {
                if (process.env.NODE_ENV !== "production") {
                    console.error(e)
                }
            })
    }, [dataProvider, id])

    if (!errors || errors.length === 0) {
        return <>No errors</>
    }

    return (
        <Table stickyHeader aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>Row</TableCell>
                    <TableCell>Template</TableCell>
                    <TableCell>Error message</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {errors.map((e, i) => (
                    <TableRow key={"error-" + i}>
                        <TableCell component="th" align="center" scope="row">
                            <b>{e.row_index}</b>
                        </TableCell>
                        <TableCell>{e.template_name}</TableCell>
                        <TableCell>{e.message}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}

export default TemplateFunnelErrors
