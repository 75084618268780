import React from "react"
import { Link } from "react-router-dom"
import {
    Datagrid,
    DateField,
    EditButton,
    Filter,
    FunctionField,
    ImageField,
    List,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    ShowButton,
    TextField,
} from "react-admin"
import { Button, Chip } from "@mui/material"

const PublicTemplateFilter = props => {
    return (
        <Filter {...props}>
            <ReferenceInput source="category_id" reference="public_template_categories" alwaysOn>
                <SelectInput optionText="name" />
            </ReferenceInput>
        </Filter>
    )
}

const PublicTemplateList = props => (
    <List {...props} perPage={50} exporter={false} filters={<PublicTemplateFilter />}>
        <Datagrid rowClick="edit">
            <TextField source="id" label="ID" />
            <TextField source="name" label="Name" />
            <ImageField source="url" title="Image" sortable={false} />
            <ReferenceField source="category_id" reference="public_template_categories" link={false}>
                <TextField source="name" />
            </ReferenceField>
            <FunctionField
                label="Dimensions"
                source="dimensions"
                sortable={false}
                render={record => {
                    if (record.dimensions.length) {
                        return record.dimensions.map(d => (
                            <React.Fragment key={"dimension_" + record.id + "_" + d}>
                                <Chip label={d} />
                                &nbsp;
                            </React.Fragment>
                        ))
                    }

                    return ""
                }}
            />
            <DateField source="created_date" label="Production date" />

            <FunctionField
                sortable={false}
                render={record => {
                    if (record.template_id) {
                        return <ShowButton resource="templates" label={"Go to template (" + record.template_id + ")"} record={{ id: record.template_id }} />
                    }

                    return <></>
                }}
            />
            <EditButton />
        </Datagrid>
    </List>
)

export default PublicTemplateList
