import React from "react"
import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, Divider, Grid, Typography } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Loading, useAuthState } from "react-admin"
import GlobalStats from "../components/Stats/GlobalStats"
import CreatedCompaniesByDay from "../components/Stats/CreatedCompaniesByDay"
import APICallsByDay from "../components/Stats/APICallsByDay"
import APICreatedTemplatesByDay from "../components/Stats/APICreatedTemplatesByDay"
import AllTimeCreativeAutomationStats from "../components/Stats/AllTimeCreativeAutomationStats"
import MostActiveCompanies from "../components/Stats/MostActiveCompanies"
import LeastConnectedCompanies from "../components/Stats/LeastConnectedCompanies"
import CompaniesWithoutbanner from "../components/Stats/CompaniesWithoutBanner"
import MostUsedPublicTemplates from "../components/Stats/MostUsedPublicTemplates"
import APICallsToday from "../components/Stats/APICallsToday"
import GeneratedBannersByMonth from "../components/Stats/GeneratedBannersByMonth"
import FormsCreatedByDay from "../components/Stats/FormsCreatedByDay"
import FormsCreatedByMonth from "../components/Stats/FormsCreatedByMonth"
import MediaCreatedByDay from "../components/Stats/MediaCreatedByDay"

const Dashboard = () => {
    const { isLoading, authenticated } = useAuthState()

    if (isLoading) {
        return <Loading />
    }

    if (!authenticated) {
        return <></>
    }

    return (
        <div>
            <Grid container spacing={4}>
                <Grid item xs={4}>
                    <GlobalStats />
                </Grid>
                <Grid item xs={8}>
                    <CreatedCompaniesByDay />
                </Grid>
            </Grid>
            <br />
            <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5">Today Stats</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <APICallsToday />
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={6}>
                            <MostActiveCompanies type="ca" criteria="api_calls" title="Most active companies: Images generated" onlyToday />
                        </Grid>
                        <Grid item xs={6}>
                            <MostActiveCompanies type="ca" criteria="templates" title="Most active companies: Templates created" onlyToday />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <br />
            <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5">Creative-automation</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <AllTimeCreativeAutomationStats />
                        </Grid>
                        <Grid item xs={6}>
                            <APICreatedTemplatesByDay />
                        </Grid>
                        <Grid item xs={6}>
                            <APICallsByDay />
                        </Grid>
                        <Grid item xs={6}>
                            <GeneratedBannersByMonth />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <br />
            <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5">Most active companies (last 31 days) - API calls</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{ width: "100%" }}>
                        <Divider />
                        <br />
                        <Grid container spacing={6}>
                            <Grid item xs={4}>
                                <MostActiveCompanies type="ca" criteria="api_calls" title="CA : All companies" />
                            </Grid>
                            <Grid item xs={4}>
                                <MostActiveCompanies type="ca" criteria="api_calls" title="CA : Only Trial" status={["TRIAL"]} />
                            </Grid>
                            <Grid item xs={4}>
                                <MostActiveCompanies type="ca" criteria="api_calls" title="CA : Only Trial ended &amp; Free" status={["TRIAL_ENDED", "FREE"]} />
                            </Grid>
                        </Grid>
                    </div>
                </AccordionDetails>
            </Accordion>
            <br />
            <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5">Most active companies (last 31 days) - Templates created</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{ width: "100%" }}>
                        <Divider />
                        <br />
                        <Grid container spacing={6}>
                            <Grid item xs={4}>
                                <MostActiveCompanies type="ca" criteria="templates" title="CA : All companies" />
                            </Grid>
                        </Grid>
                    </div>
                </AccordionDetails>
            </Accordion>
            <br />
            <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5">Least connected companies (last 31 days)</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{ width: "100%" }}>
                        <Divider />
                        <br />
                        <Grid container>
                            <LeastConnectedCompanies title="" />
                        </Grid>
                    </div>
                </AccordionDetails>
            </Accordion>
            <br />
            <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5">Companies without banners (last 31 days)</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{ width: "100%" }}>
                        <Divider />
                        <br />
                        <Grid container>
                            <CompaniesWithoutbanner type="ca" title="" />
                        </Grid>
                    </div>
                </AccordionDetails>
            </Accordion>
            <br />
            <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5">Most used public templates (last 31 days)</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{ width: "100%" }}>
                        <Divider />
                        <br />
                        <Grid container spacing={6}>
                            <Grid item xs={6}>
                                <MostUsedPublicTemplates order="DESC" title="Most used Public templates" />
                            </Grid>
                            <Grid item xs={6}>
                                <MostUsedPublicTemplates order="ASC" title="Least used Public Templates" />
                            </Grid>
                        </Grid>
                    </div>
                </AccordionDetails>
            </Accordion>
            <br />
            <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5">Template forms</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{ width: "100%" }}>
                        <Divider />
                        <br />
                        <Grid container spacing={6}>
                            <Grid item xs={6}>
                                <FormsCreatedByDay />
                            </Grid>
                            <Grid item xs={6}>
                                <FormsCreatedByMonth />
                            </Grid>
                        </Grid>
                    </div>
                </AccordionDetails>
            </Accordion>
            <br />
            <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5">Media by type</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{ width: "100%" }}>
                        <Divider />
                        <br />
                        <Grid container spacing={6}>
                            <Grid item xs={6}>
                                <MediaCreatedByDay />
                            </Grid>
                            <Grid item xs={6}>
                                {/*<MediaCreatedByMonth /> SQL REQUEST IS TOO HEAVY */}
                            </Grid>
                        </Grid>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default Dashboard
