import React from "react"
import { Datagrid, Filter, FunctionField, List, NumberInput, useRecordContext } from "react-admin"
import get from "lodash/get"
import { Button } from "@mui/material"
import { Link } from "react-router-dom"

const UserFilter = props => (
    <Filter {...props}>
        <NumberInput alwaysOn source="id" label="Search by ID" />
    </Filter>
)

const UserLinkField = ({ source, type, filters = {} }) => {
    const record = useRecordContext()

    if (!record) {
        return <></>
    }

    const value = parseInt(get(record, source))

    if (!value || isNaN(value)) {
        return <></>
    }

    const transformedFilters = {}

    for (const index in filters) {
        if (filters[index] in record) {
            transformedFilters[index] = record[filters[index]]
        } else {
            transformedFilters[index] = filters[index]
        }
    }

    return (
        <Button
            component={Link}
            size="small"
            color="primary"
            target="_blank"
            to={{
                pathname: "/" + type,
                search: "filter=" + JSON.stringify(transformedFilters),
            }}>
            {value}
        </Button>
    )
}

export const SemrushUserList = props => (
    <List {...props} filters={<UserFilter />} exporter={false} bulkActionButtons={false} sort={{ field: "id", order: "DESC" }}>
        <Datagrid>
            <FunctionField label="ID" sortBy="id" render={record => `#${record.id}`} />

            <UserLinkField
                source="number_of_funnels"
                label="Funnels"
                type="semrush_funnels"
                filters={{
                    user_id: "id",
                }}
            />
            <UserLinkField
                source="number_of_banners"
                label="Banners"
                type="semrush_banners"
                filters={{
                    user_id: "id",
                }}
            />
        </Datagrid>
    </List>
)
