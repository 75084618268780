import React from "react"
import { Authenticated, NotFound } from "react-admin"

const AbyssaleNotFound = () => (
    <Authenticated>
        <NotFound />
    </Authenticated>
)

export default AbyssaleNotFound
