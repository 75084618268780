import React from "react"
import { FunctionField } from "react-admin"
import PropTypes from "prop-types"
import { withStyles } from "@mui/styles"
import { Box, Button, Card, CardContent, Divider, Typography } from "@mui/material"
import moment from "moment"
import { Link } from "react-router-dom"
import clsx from "clsx"
import MoveTemplatePopin from "./MoveTemplatePopin"

const styles = {
    bannerCard: {
        margin: "0.5em",
        display: "inline-block",
        verticalAlign: "top",
    },
    normalBannerCard: {
        width: 300,
        "& svg, & img": {
            maxWidth: "300px",
            maxHeight: "300px",
        },
    },
    iab728x90BannerCard: {
        width: "calc(600px + 1em)",
        "& svg, & img": {
            maxWidth: "calc(600px + 1em)",
            maxHeight: "76px",
        },
    },
    fbFeedBannerCard: {
        "& svg, & img": {
            maxHeight: "157px",
        },
    },
    twFeedBannerCard: {
        "& svg, & img": {
            maxHeight: "168px",
        },
    },
    ratingArea: {
        position: "relative",
        left: "-16px",
    },
    smallStar: {
        width: 20,
        height: 20,
    },
    historyActionList: {
        margin: 0,
        padding: 0,
        "& > li": {
            listStyleType: "none",
        },
    },
    actionArea: {
        backgroundColor: "#FAFAFA",
        justifyContent: "space-between",
    },
    bannerContent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        "& > img": {
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit: "contain",
        },
        "& svg": {
            borderBottom: "1px solid #EAEAEA",
        },
    },
    smallButton: {
        padding: "0",
        minWidth: "auto",
        fontSize: "0.75rem",
        minHeight: "auto",
        "&:hover": {
            backgroundColor: "transparent",
            color: "#444",
        },
    },
    mediumButton: {
        backgroundColor: "grey",
        "&:hover": {
            backgroundColor: "transparent",
            color: "#444",
        },
    },
}

const TemplateCardField = ({ record = {}, classes = {}, display = "small" }) => {
    if (!record) {
        return null
    }

    return (
        <Card className={clsx(classes.bannerCard, display !== "raw" && classes.normalBannerCard)}>
            {"url" in record && (
                <div className={classes.bannerContent}>
                    {"animated_url" in record && record["animated_url"] ? (
                        <Box
                            component="video"
                            src={record.animated_url}
                            alt="Thumbnail Video"
                            controls
                            disableRemotePlayback
                            poster={record["url"]}
                            sx={{
                                maxWidth: "300px",
                                maxHeight: "300px",
                                objectFit: "contain",
                            }}
                        />
                    ) : (
                        <img src={record.url} alt="Banner" />
                    )}
                </div>
            )}

            <CardContent>
                <Typography component="h4">{record.name}</Typography>
                <Divider sx={{ mt: "10px", mb: "10px" }} />
                <Typography component="p" variant="caption">
                    Template ID:{" "}
                    <b>
                        <Button
                            component={Link}
                            size="small"
                            color="primary"
                            className={classes.smallButton}
                            to={{
                                pathname: "/templates/" + record.id + "/show",
                            }}>
                            #{record.id}
                        </Button>
                    </b>{" "}
                </Typography>
                <Typography component="p" variant="caption">
                    Template UUID:&nbsp;<b>{record.company_template_uuid}</b>
                </Typography>
                <Typography component="p" variant="caption">
                    Template type:&nbsp;<b>{record.type}</b>
                </Typography>
                <Typography component="p" variant="caption">
                    Current version:&nbsp;<b>{record.version}</b>
                    <br />
                    <i>({record.template_uuid})</i>
                </Typography>
                <Typography component="p" variant="caption">
                    Filter by Company:&nbsp;
                    <Button
                        component={Link}
                        size="small"
                        color="primary"
                        target="_blank"
                        className={classes.smallButton}
                        to={{
                            pathname: "/templates",
                            search:
                                "filter=" +
                                JSON.stringify({
                                    company_id: record.company_id,
                                }),
                        }}>
                        {record.company_id}
                        <Typography component="span" variant="caption" style={{ textTransform: "none", fontWeight: "0.5em" }}>
                            &nbsp;({record.company_name})
                        </Typography>
                    </Button>
                </Typography>
                <Typography component="p" variant="caption">
                    Updated at: {moment(record.created_at_ts * 1000, "x").format("YYYY-MM-DD HH:mm")}
                </Typography>

                <FunctionField
                    label="Action"
                    sortable={false}
                    render={() => {
                        return <MoveTemplatePopin templateId={record.id} companyId={record.company_id} />
                    }}
                />
            </CardContent>
        </Card>
    )
}

TemplateCardField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
}

export default withStyles(styles)(TemplateCardField)
