import React from "react"
import { BooleanField, Datagrid, DateField, FunctionField, Pagination, ReferenceManyField, Show, Tab, TabbedShowLayout, TextField } from "react-admin"

import BannerBuilderGrid from "../components/BannerBuilderGrid"
import TemplateGrid from "../components/TemplateGrid"
import CompanySummaryGrid from "../components/CompanySummaryGrid"
import ChipStatus from "../components/ChipStatus"
import BandwidthUsage from "../components/BandwidthUsage"
import { List } from "@mui/material"

const platform_url = window.REACT_APP_PLATFORM_URL

const InvitationCodeField = ({ record = {} }) => (
    <span>
        <a href={platform_url + "/invite/" + record.invitation_code}>{record.invitation_code}</a>
    </span>
)

export const CompanyShow = () => {
    return (
        <Show>
            <TabbedShowLayout>
                <Tab label="Summary">
                    <CompanySummaryGrid />
                </Tab>
                <Tab label="Users" path="users">
                    <ReferenceManyField reference="users" target="company_id" addLabel={false}>
                        <Datagrid bulkActionButtons={false}>
                            <TextField source="id" />
                            <TextField source="email" />
                            <TextField source="uuid" sortable={false} />
                            <BooleanField source="is_owner" sortable={false} />
                            <TextField source="role" sortable={false} />
                            <ChipStatus source="status" label="User status" sortable={false} />
                            <ChipStatus source="account_status" sortable={false} />
                            <ChipStatus source="invitation_status" label="Invitation status" sortable={false} />
                            <FunctionField
                                label="Action"
                                sortable={false}
                                render={record => {
                                    // if (record.is_owner === true) {
                                    return <></>
                                    // }
                                    // return <RemoveUserFromCompanyPopin companyId={props.id} userId={record.id} accountStatus={record.account_status} />
                                }}
                            />
                            <InvitationCodeField source="invitation_code" sortable={false} />
                            <DateField source="invited_date" />
                            <DateField source="last_connection_date" />
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>

                <Tab label="Created templates" path="created_templates">
                    <ReferenceManyField
                        reference="templates"
                        target="company_id"
                        addLabel={false}
                        sort={{ field: "id", order: "DESC" }}
                        pagination={<Pagination />}
                        filter={{ hide_internal_users: false }}>
                        <List>
                            <TemplateGrid />
                        </List>
                    </ReferenceManyField>
                </Tab>

                <Tab label="Last API calls" path="api_calls">
                    <ReferenceManyField
                        reference="banner_builder"
                        target="company_id"
                        addLabel={false}
                        pagination={<Pagination />}
                        sort={{ field: "id", order: "DESC" }}
                        filter={{ hide_internal_users: false, latest: true }}>
                        <List>
                            <BannerBuilderGrid />
                        </List>
                    </ReferenceManyField>
                </Tab>
                <Tab label="Bandwidth">
                    <BandwidthUsage />
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
}
