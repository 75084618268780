import React, { useEffect, useState } from "react"
import { CircularProgress, Typography } from "@mui/material"
import { useDataProvider } from "react-admin"
import { ColumnChart } from "react-chartkick"
import "chartkick/chart.js"

const APICallsByDay = ({ companyId = null, hideInternalUsers = true }) => {
    const [stats, setStats] = useState([])
    const [fetching, setFetching] = useState(true)
    const [total, setTotal] = useState(0)

    const dataProvider = useDataProvider()

    useEffect(() => {
        dataProvider
            .get("/stats/api_calls_today?hide_internal_users=" + hideInternalUsers + (companyId ? "&company_id=" + companyId : ""))
            .then(results => {
                if (results) {
                    const rawStats = results.data
                    const total = rawStats.hours
                        .map(d => {
                            return (
                                d.api +
                                d.funnel +
                                d.airtable +
                                d.integromat +
                                d.template_page +
                                d.wordpress +
                                d.zapier +
                                d["images-api"] +
                                d["forms"] +
                                d["single-form"]
                            )
                        })
                        .reduce((a, b) => a + b, 0)

                    const hours = []

                    for (const type of [
                        "api",
                        "funnel",
                        "airtable",
                        "integromat",
                        "template_page",
                        "wordpress",
                        "zapier",
                        "images-api",
                        "forms",
                        "single-form",
                    ]) {
                        hours.push({
                            name: type,
                            data: results.data.hours.map(d => {
                                return [d.hour, d[type]]
                            }),
                        })
                    }
                    rawStats["hours"] = hours

                    setTotal(total)
                    setStats(rawStats)
                    setFetching(false)
                }
            })
            .catch(e => {
                if (process.env.NODE_ENV !== "production") {
                    console.error(e)
                }
            })
    }, [dataProvider, companyId, hideInternalUsers])

    if (fetching) {
        return <CircularProgress />
    }

    return (
        <>
            <Typography component="h1" variant="h5" align="center" gutterBottom>
                {total} successful API Calls (split / hour) (UTC)
            </Typography>
            <ColumnChart data={stats.hours} stacked />
        </>
    )
}

export default APICallsByDay
