import React, { useEffect, useState } from "react"
import { Link, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import { Link as RouterLink } from "react-router-dom"
import { useDataProvider } from "react-admin"
import "chartkick/chart.js"

const MostUsedPublicTemplates = ({ title, hideInternalUsers = true, numberOfDays = 31, order }) => {
    const [templates, setTemplates] = useState([])

    const dataProvider = useDataProvider()

    useEffect(() => {
        dataProvider
            .get("/stats/most_used_public_templates?hide_internal_users=" + hideInternalUsers + "&number_of_days=" + numberOfDays + "&order=" + order)
            .then(results => {
                if (results) {
                    setTemplates(results.data)
                }
            })
            .catch(e => {
                if (process.env.NODE_ENV !== "production") {
                    console.error(e)
                }
            })
    }, [dataProvider, hideInternalUsers, numberOfDays])

    return (
        <>
            <Typography component="h1" variant="h6" align="center" gutterBottom>
                {title}
            </Typography>
            <Table>
                <TableHead>
                    <TableRow style={{ backgroundColor: "#EAEAEA" }}>
                        <TableCell>Template name</TableCell>
                        <TableCell>Use Number</TableCell>
                        <TableCell>Creation Date</TableCell>
                        <TableCell>Update Date</TableCell>
                        <TableCell>Last Usage Date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {templates.map(template => (
                        <TableRow key={template.template_id}>
                            <TableCell component="th" scope="row">
                                <Link
                                    component={RouterLink}
                                    variant="subtitle1"
                                    to={{
                                        pathname: "/public_templates/" + template.template_id + "/show",
                                    }}>
                                    {template.template_name}
                                </Link>
                            </TableCell>
                            <TableCell>
                                <b>{template.total}</b>
                            </TableCell>
                            <TableCell>
                                <b>{template.created_at}</b>
                            </TableCell>
                            <TableCell>
                                <b>{template.updated_at}</b>
                            </TableCell>
                            <TableCell>
                                <b>{template.last_usage}</b>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    )
}

export default MostUsedPublicTemplates
