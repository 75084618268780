import React from "react"
import { Edit, Filter, ImageField, ReferenceInput, required, SelectInput, SimpleForm, TextInput } from "react-admin"

const PublicTemplateEdit = props => (
    <Edit mutationMode="optimistic" {...props}>
        <SimpleForm>
            <TextInput source="name" label="Name" validate={[required()]} />
            <ReferenceInput source="category_id" reference="public_template_categories">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ImageField source="url" label="Image URL" />
        </SimpleForm>
    </Edit>
)

export default PublicTemplateEdit
