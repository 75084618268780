import React from "react"
import { Create, FormDataConsumer, ImageField, ImageInput, required, SelectInput, SimpleForm, TextInput } from "react-admin"

const CarouselCreate = props => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source="link_url" label="Link url" validate={[required()]} />
                <SelectInput
                    source="type"
                    choices={[
                        { id: "DYNAMIC_IMAGES", name: "Dynamic images" },
                        { id: "DASHBOARD", name: "Dashboard" },
                        { id: "MINI_DASHBOARD", name: "Mini Dashboard" },
                        { id: "DASHBOARD_TRIAL", name: "Dashboard trial" },
                        { id: "PUBLIC_TEMPLATES", name: "Public templates" },
                        { id: "AUTOMATE", name: "Automate" },
                    ]}
                    alwaysOn
                />

                <ImageInput source="image_url_data" label="Image Url" accept="image/*" multiple={false} validate={[required()]}>
                    <ImageField source="image_url_data" />
                </ImageInput>
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.type === "MINI_DASHBOARD" && (
                            <>
                                <TextInput source="category" label="Category" validate={[required()]} />
                                <SelectInput
                                    source="color"
                                    validate={[required()]}
                                    choices={[
                                        { id: "PURPLE", name: "purple" },
                                        { id: "ORANGE", name: "orange" },
                                        { id: "BLUE", name: "blue" },
                                        { id: "GREEN", name: "green" },
                                    ]}
                                    alwaysOn
                                />
                                <TextInput source="title" label="Title" validate={[required()]} />
                                <TextInput source="description" label="Description" validate={[required()]} />
                            </>
                        )
                    }
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    )
}

export default CarouselCreate
