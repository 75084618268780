import React, { useEffect } from "react"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { Button, NumberInput, required, SaveButton, SimpleForm, Toolbar, useDataProvider, useNotify, useRefresh } from "react-admin"
import { Typography } from "@mui/material"

const RemoveConsumedCreditsPopin = ({ consumedCredits, creditsLimitation, companyName }) => {
    const [open, setOpen] = React.useState(false)
    const [isLoading, setIsLoadIng] = React.useState(false)
    const [remainingCredits, setRemainingCredits] = React.useState(consumedCredits)
    const notify = useNotify()
    const refresh = useRefresh()
    const dataProvider = useDataProvider()

    useEffect(() => {
        setRemainingCredits(consumedCredits)
    }, [consumedCredits])

    const postSave = data => {
        if (data["consumed_credits"] === 0) {
            notify("The company has already 0 consumed credits.", "warning")
            return
        }

        if (consumedCredits - data["consumed_credits"] < 0) {
            notify("The company consumed credits can't be negative.", "warning")
            return
        }

        setIsLoadIng(true)
        dataProvider
            .create("company/" + data["id"] + "/remove-credits", {
                data: {
                    company_uuid: data["uuid"],
                    removed_credits: data["removed_credits"],
                },
            })
            .then(() => {
                handleClose()
                refresh()
                notify("Credits removed")
            })
            .catch(e => {
                notify(`Error during credits removal: ${e.message}`, "warning")
            })
            .finally(() => {
                setIsLoadIng(false)
            })
    }

    const handleClose = () => {
        setOpen(false)
    }

    const PostEditToolbar = props => (
        <Toolbar {...props}>
            <Button label="Cancel" onClick={handleClose} color="primary" />
            <SaveButton sx={{ ml: "30px" }} alwaysEnable />
        </Toolbar>
    )

    return (
        <>
            <Button
                label="Remove consumed credits"
                variant="contained"
                onClick={() => {
                    setOpen(true)
                }}
            />
            <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle>Remove consumed credits from company {companyName}</DialogTitle>
                <DialogContent>
                    <SimpleForm onSubmit={postSave} toolbar={<PostEditToolbar />}>
                        <Typography>
                            Current consumed credits:{" "}
                            <b>
                                {consumedCredits} / {creditsLimitation !== -1 ? <>{creditsLimitation}</> : <>unlimited</>}
                            </b>
                        </Typography>
                        <Typography>
                            Credits consumed once removed:{" "}
                            <b>
                                {remainingCredits} / {creditsLimitation !== -1 ? <>{creditsLimitation}</> : <>unlimited</>}
                            </b>
                        </Typography>
                        <NumberInput
                            min={1}
                            max={100000}
                            fullWidth
                            validate={[required()]}
                            label="Number of credits to remove"
                            source="removed_credits"
                            disabled={isLoading}
                            onChange={e => {
                                setRemainingCredits(consumedCredits - e.target.value)
                            }}
                        />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default RemoveConsumedCreditsPopin
