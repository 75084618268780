import React from "react"
import {
    Button,
    ChipField,
    Datagrid,
    DateField,
    EmailField,
    Filter,
    FunctionField,
    List,
    NumberInput,
    ReferenceManyField,
    SelectInput,
    SingleFieldList,
    TextField,
    TextInput,
    useDataProvider,
    useNotify,
    useRecordContext,
} from "react-admin"
import { useMutation } from "react-query"

import ChipStatus from "../components/ChipStatus"

const UserFilter = props => (
    <Filter {...props}>
        <TextInput alwaysOn label="Search by email" source="email" />
        <NumberInput alwaysOn source="id" label="Search by ID" />
        <TextInput alwaysOn source="uuid" label="Search by UUID" />
        <SelectInput
            source="status"
            choices={[
                { id: "PENDING", name: "Pending" },
                { id: "CREATED", name: "Created" },
                { id: "UNVERIFIED", name: "Unverified" },
            ]}
            alwaysOn
        />
    </Filter>
)

const StatusButton = ({ id, label }) => {
    const notify = useNotify()
    const dataProvider = useDataProvider()

    const { mutate, isLoading } = useMutation(() => dataProvider.update("activate_user", { id: id }), {
        onSuccess: ({ data }) => {
            notify("The user's account " + id + " is activated")
        },
        onError: error => notify(`Error during uploading the user status: ${error.message}`, "warning"),
    })
    return <Button label={label} onClick={mutate} disabled={isLoading} />
}

const UserStatusActions = () => {
    const record = useRecordContext()
    return <>{["UNVERIFIED"].includes(record.status) && <StatusButton id={record.id} label="Activate account" />}</>
}

export const UserList = props => (
    <List {...props} filters={<UserFilter />} exporter={false} sort={{ field: "id", order: "DESC" }}>
        <Datagrid bulkActionButtons={false}>
            <FunctionField label="ID" sortBy="id" render={record => `#${record.id}`} />
            <TextField source="uuid" sortable={false} />
            <EmailField source="email" />
            <ChipStatus source="status" />
            <ReferenceManyField label="Companies" reference="companies" target="user_id" sortable={false}>
                <SingleFieldList linkType="show">
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceManyField>
            <DateField source="created_date" />
            <DateField source="last_connection_date" />
            <UserStatusActions />
        </Datagrid>
    </List>
)
