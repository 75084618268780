import { useState } from "react"
import { Button, Create, ReferenceInput, SelectInput, SimpleForm, useNotify, useRedirect, useRefresh } from "react-admin"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"

const CreatePublicTemplatesButton = props => {
    const [open, setOpen] = useState(false)
    const handleClick = () => setOpen(true)
    const handleDialogClose = () => setOpen(false)

    const notify = useNotify()
    const refresh = useRefresh()
    const redirect = useRedirect()

    const onSuccess = () => {
        notify(`Public Templates created`)
        redirect("/public_templates")
        refresh()
    }

    return (
        <>
            <Button label="Create Public Templates" onClick={handleClick} />

            <Dialog onClose={handleDialogClose} open={open} fullWidth>
                <DialogTitle>
                    Create Public Templates from <b>{props.selectedIds.length}</b> selected template{props.selectedIds.length > 1 && "s"}
                </DialogTitle>

                <Create
                    resource="public_templates_from_templates"
                    record={{
                        template_ids: props.selectedIds,
                    }}
                    mutationOptions={{ onSuccess }}
                    undoable={false}>
                    <SimpleForm>
                        <ReferenceInput source="category_id" reference="public_template_categories">
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                    </SimpleForm>
                </Create>
            </Dialog>
        </>
    )
}

export default CreatePublicTemplatesButton
