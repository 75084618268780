import React, { useEffect, useState } from "react"
import { CircularProgress, Typography } from "@mui/material"
import { useDataProvider } from "react-admin"
import { ColumnChart } from "react-chartkick"
import "chartkick/chart.js"

const FormsCreatedByMonth = ({ numberOfMonths = 12, companyId = null, hideInternalUsers = true }) => {
    const [stats, setStats] = useState([])
    const [fetching, setFetching] = useState(true)
    const [total, setTotal] = useState(0)

    const dataProvider = useDataProvider()

    useEffect(() => {
        dataProvider
            .get(
                "/stats/created_forms_by_month?hide_internal_users=" +
                    hideInternalUsers +
                    "&number_of_months=" +
                    numberOfMonths +
                    (companyId ? "&company_id=" + companyId : "")
            )
            .then(results => {
                if (results) {
                    setTotal(results.data.last_x_months.map(d => d.total).reduce((a, b) => a + b, 0))
                    setStats(
                        results.data.last_x_months.map(d => {
                            return [d.generation_month + "/" + (d.generation_year % 100), d.total]
                        })
                    )
                    setFetching(false)
                }
            })
            .catch(e => {
                if (process.env.NODE_ENV !== "production") {
                    console.error(e)
                }
            })
    }, [dataProvider, companyId, hideInternalUsers, numberOfMonths])

    if (fetching) {
        return <CircularProgress />
    }

    return (
        <>
            <Typography component="h1" variant="h5" align="center" gutterBottom>
                {total} Forms <i>(Last {numberOfMonths} Months)</i>
            </Typography>
            <ColumnChart data={stats} />
        </>
    )
}

export default FormsCreatedByMonth
