import React from "react"
import { Admin, Resource } from "react-admin"
import { QueryClient } from "react-query"
import { SemrushFunnelList } from "./pages/Semrush/SemrushFunnelList"
import { BannerBuilderList } from "./pages/BannerBuilderList"
import { UserList } from "./pages/UserList"
import { SemrushUserList } from "./pages/Semrush/SemrushUserList"
import { SemrushBannerList } from "./pages/Semrush/SemrushBannerList"
import { SemrushBannerShow } from "./pages/Semrush/SemrushBannerShow"
import { BannerBuilderShow } from "./pages/BannerBuilderShow"
import { CompanyShow } from "./pages/CompanyShow"
import { CompanyList } from "./pages/CompanyList"
import { TemplateList } from "./pages/TemplateList"
import { TemplateFunnelList } from "./pages/TemplateFunnelList"
import { BrowserRouter } from "react-router-dom"

import NotFound from "./pages/NotFound"
import Dashboard from "./pages/Dashboard"
import ImageIcon from "@mui/icons-material/Image"
import BusinessIcon from "@mui/icons-material/Business"
import ArtTrackIcon from "@mui/icons-material/ArtTrack"
import FormatShapesIcon from "@mui/icons-material/FormatShapes"
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel"
import ListIcon from "@mui/icons-material/List"
import FaceIcon from "@mui/icons-material/Face"
import PublicIcon from "@mui/icons-material/Public"
import Assignment from "@mui/icons-material/Assignment"
import BurstMode from "@mui/icons-material/BurstMode"
import polyglotI18nProvider from "ra-i18n-polyglot"
import englishMessages from "ra-language-english"

import dataProvider from "./utils/dataProvider"
import authProvider from "./utils/authProvider"
import "./index.css"
import { TemplateShow } from "./pages/TemplateShow"

import PublicTemplateEdit from "./pages/PublicTemplates/PublicTemplateEdit"
import PublicTemplateList from "./pages/PublicTemplates/PublicTemplateList"
import CarouselList from "./pages/Carousel/CarouselList"
import CarouselCreate from "./pages/Carousel/CarouselCreate"
import CarouselEdit from "./pages/Carousel/CarouselEdit"
import { TemplateFormList } from "./pages/TemplateFormList"
import { DynamicImageList } from "./pages/DynamicImageList"
import LoginPage from "./pages/LoginPage"
import { ThemeProvider } from "@mui/material"
import { createTheme } from "@mui/material/styles"

// Disable translation warnings
const i18nProvider = polyglotI18nProvider(() => englishMessages, "en", {
    allowMissing: true,
})

const theme = createTheme()
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            staleTime: Infinity,
        },
    },
})

const App = () => (
    <ThemeProvider theme={theme}>
        <BrowserRouter>
            <Admin
                requireAuth
                catchAll={NotFound}
                loginPage={LoginPage}
                dashboard={Dashboard}
                authProvider={authProvider}
                dataProvider={dataProvider}
                i18nProvider={i18nProvider}
                queryClient={queryClient}
                disableTelemetry>
                <Resource name="companies" list={CompanyList} icon={BusinessIcon} show={CompanyShow} />
                <Resource name="users" list={UserList} icon={FaceIcon} />

                <Resource name="template_funnels" options={{ label: "Creative-auto funnels" }} list={TemplateFunnelList} icon={ListIcon} />
                <Resource
                    name="banner_builder"
                    options={{ label: "Creative-auto banners" }}
                    list={BannerBuilderList}
                    icon={ArtTrackIcon}
                    show={BannerBuilderShow}
                />

                <Resource name="templates" list={TemplateList} icon={FormatShapesIcon} show={TemplateShow} />
                <Resource name="public_templates" list={PublicTemplateList} icon={PublicIcon} edit={PublicTemplateEdit} />
                <Resource name="public_templates_from_templates" />
                <Resource name="carousels" list={CarouselList} icon={ViewCarouselIcon} edit={CarouselEdit} create={CarouselCreate} />
                <Resource name="template_forms" list={TemplateFormList} icon={Assignment} />
                <Resource name="dynamic_images" list={DynamicImageList} icon={BurstMode} />

                <Resource name="public_template_categories" />
                <Resource name="abyssale_templates" />
                <Resource name="company_bandwidth" />

                <Resource name="semrush_user" list={SemrushUserList} icon={FaceIcon} />
                <Resource name="semrush_funnels" options={{ label: "Semrush funnels" }} list={SemrushFunnelList} icon={ListIcon} />
                <Resource name="semrush_banners" options={{ label: "Semrush banners" }} list={SemrushBannerList} icon={ImageIcon} show={SemrushBannerShow} />
            </Admin>
        </BrowserRouter>
    </ThemeProvider>
)

export default App
