import React from "react"
import { BooleanInput, Filter, List, TextInput } from "react-admin"
import BannerBuilderGrid from "../components/BannerBuilderGrid"

const BannerBuilderFilter = props => {
    return (
        <Filter {...props}>
            <TextInput source="company_id" label="Company ID" alwaysOn />
            <TextInput source="user_id" label="User ID" />
            <TextInput source="funnel_processing_id" label="Funnel processing ID" />
            <TextInput source="template_ids" label="Template IDs" />
            <BooleanInput source="hide_internal_users" label="Hide internal users" defaultValue={true} />
        </Filter>
    )
}

export const BannerBuilderList = props => (
    <List
        filters={<BannerBuilderFilter />}
        {...props}
        exporter={false}
        bulkActionButtons={false}
        sort={{ field: "id", order: "DESC" }}
        filterDefaultValues={{ hide_internal_users: true }}>
        <BannerBuilderGrid />
    </List>
)
