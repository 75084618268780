import React, { useEffect, useState } from "react"
import { Card, CardContent, Typography } from "@mui/material"
import { useDataProvider } from "react-admin"
import moment from "moment"
import { LineChart } from "react-chartkick"
import "chartkick/chart.js"

const CreatedCompaniesByDay = ({ numberOfDays = 31 }) => {
    const [stats, setStats] = useState([])
    const dataProvider = useDataProvider()

    useEffect(() => {
        dataProvider
            .get("/stats/created_companies_by_day?number_of_days=" + numberOfDays)
            .then(results => {
                if (results) {
                    setStats(
                        results.data.last_x_days.map(d => {
                            const currentMonth = moment(d.created_date).format("MMM Do")
                            return [[currentMonth], d.total]
                        })
                    )
                }
            })
            .catch(e => {
                if (process.env.NODE_ENV !== "production") {
                    console.error(e)
                }
            })
    }, [dataProvider, numberOfDays])

    return (
        <Card>
            <CardContent>
                <Typography component="h1" variant="h5" align="center">
                    Created companies <i>(Last {numberOfDays} days)</i>
                </Typography>
                {stats && <LineChart data={Object.fromEntries(stats)} />}
            </CardContent>
        </Card>
    )
}

export default CreatedCompaniesByDay
