import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@mui/styles"
import { Button, Card, CardContent, Grid, Typography } from "@mui/material"
import moment from "moment"
import { Link } from "react-router-dom"
import clsx from "clsx"
import BannerChipStatus from "./BannerChipStatus"

const getFormatId = formatName => {
    if (formatName === "FB_FEED") {
        return 2
    }
    if (formatName === "LI_FEED") {
        return 3
    }
    if (formatName === "TW_FEED") {
        return 4
    }
    if (formatName === "TW_DMC") {
        return 5
    }
    if (formatName === "FB_SQUARE") {
        return 8
    }
}

const styles = {
    bannerCard: {
        margin: "0.5em",
        display: "inline-block",
        verticalAlign: "top",
    },
    normalBannerCard: {
        width: 300,
        "& svg, & img": {
            maxWidth: "300px",
            maxHeight: "300px",
        },
    },
    fbFeedBannerCard: {
        "& svg, & img": {
            maxHeight: "157px",
        },
    },
    twFeedBannerCard: {
        "& svg, & img": {
            maxHeight: "168px",
        },
    },
    ratingArea: {
        position: "relative",
        left: "-16px",
    },
    smallStar: {
        width: 20,
        height: 20,
    },
    historyActionList: {
        margin: 0,
        padding: 0,
        "& > li": {
            listStyleType: "none",
        },
    },
    actionArea: {
        backgroundColor: "#FAFAFA",
        justifyContent: "space-between",
    },
    bannerContent: {
        "& > img": {
            width: "100%",
            objectFit: "contain",
        },
        "& svg": {
            borderBottom: "1px solid #EAEAEA",
        },
    },
    smallButton: {
        padding: "0",
        minWidth: "auto",
        fontSize: "0.75rem",
        minHeight: "auto",
        "&:hover": {
            backgroundColor: "transparent",
            color: "#444",
        },
    },
}

const SemrushBannerCardField = ({ record = {}, classes = {}, display = "small" }) => {
    if (!record) {
        return null
    }

    const history = []

    const actions = [{ columnName: "created_at_ts", displayText: "Created" }]

    for (const action of actions) {
        if (action["columnName"] in record && record[action["columnName"]]) {
            history.push([action["displayText"], moment(record[action["columnName"]] * 1000, "x").format("YYYY-MM-DD HH:mm")])
        }
    }

    return (
        <Card
            className={clsx(
                classes.bannerCard,
                display !== "raw" && classes.normalBannerCard,
                display !== "raw" && ["FB_FEED", "LI_FEED", "TW_DMC"].includes(record.banner_format) && classes.fbFeedBannerCard,
                display !== "raw" && record.banner_format === "TW_FEED" && classes.twFeedBannerCard
            )}>
            {"url" in record && (
                <div className={classes.bannerContent}>
                    <img src={record.url} alt="Banner" />
                </div>
            )}
            {"content" in record && <div className={classes.bannerContent} dangerouslySetInnerHTML={{ __html: record.content }} />}
            <CardContent>
                <Grid container alignItems="center" justify="space-between">
                    <Grid item>
                        <Typography component="h6" variant="caption">
                            ID:&nbsp;
                            <b>
                                <Button
                                    component={Link}
                                    color="primary"
                                    className={classes.smallButton}
                                    to={{
                                        pathname: "/semrush_banners/" + record.id + "/show",
                                    }}>
                                    #{record.id}
                                </Button>
                            </b>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <BannerChipStatus chipLabel={record.state} />
                    </Grid>
                </Grid>

                <Typography component="h6" variant="caption">
                    Filter by:
                </Typography>
                <Typography component="p" variant="caption">
                    - Template ID:&nbsp;
                    <Button
                        component={Link}
                        size="small"
                        color="primary"
                        target="_blank"
                        className={classes.smallButton}
                        to={{
                            pathname: "/semrush_banners",
                            search:
                                "filter=" +
                                JSON.stringify({
                                    template_id: record.template_id,
                                }),
                        }}>
                        {record.template_id}
                    </Button>
                    / Format:&nbsp;
                    <Button
                        component={Link}
                        size="small"
                        color="primary"
                        target="_blank"
                        className={classes.smallButton}
                        to={{
                            pathname: "/semrush_banners",
                            search:
                                "filter=" +
                                JSON.stringify({
                                    format_id: getFormatId(record.banner_format),
                                }),
                        }}>
                        <b>{record.banner_format}</b>
                    </Button>
                </Typography>
                <Typography component="p" variant="caption">
                    - User:&nbsp;
                    <Button
                        component={Link}
                        size="small"
                        color="primary"
                        target="_blank"
                        className={classes.smallButton}
                        to={{
                            pathname: "/semrush_banners",
                            search:
                                "filter=" +
                                JSON.stringify({
                                    user_id: record.user_id,
                                }),
                        }}>
                        {record.user_id}
                    </Button>
                    / Funnel:&nbsp;
                    <Button
                        component={Link}
                        size="small"
                        color="primary"
                        target="_blank"
                        className={classes.smallButton}
                        to={{
                            pathname: "/semrush_banners",
                            search:
                                "filter=" +
                                JSON.stringify({
                                    funnel_id: record.funnel_id,
                                }),
                        }}>
                        {record.funnel_id}
                    </Button>
                </Typography>
                <br />
                <Typography component="p" variant="caption">
                    Generated time:&nbsp;<b>{record.generation_time_ms}</b> ms
                </Typography>
            </CardContent>
        </Card>
    )
}

SemrushBannerCardField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
}

export default withStyles(styles)(SemrushBannerCardField)
