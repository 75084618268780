import React, { useEffect, useState } from "react"
import { CircularProgress, Typography } from "@mui/material"
import { useDataProvider } from "react-admin"
import { ColumnChart } from "react-chartkick"
import "chartkick/chart.js"

const MediaCreatedByDay = ({ numberOfDays = 31, companyId = null, hideInternalUsers = true }) => {
    const [stats, setStats] = useState([])
    const [fetching, setFetching] = useState(true)
    const [total, setTotal] = useState(0)

    const dataProvider = useDataProvider()

    useEffect(() => {
        dataProvider
            .get(
                "/stats/created_media_by_day?hide_internal_users=" +
                    hideInternalUsers +
                    "&number_of_days=" +
                    numberOfDays +
                    (companyId ? "&company_id=" + companyId : "")
            )
            .then(results => {
                if (results) {
                    const total = results.data.last_x_days.map(d => d.static + d.pdf + d.video + d.html5 + d.gif).reduce((a, b) => a + b, 0)
                    const last_x_days = []

                    for (const type of ["static", "pdf", "video", "html5", "gif"]) {
                        const totalType = results.data.last_x_days.map(d => d[type]).reduce((a, b) => a + b, 0)
                        last_x_days.push({
                            name: totalType + " " + type + " (" + Math.round((totalType / total) * 10000) / 100 + "%)",
                            data: results.data.last_x_days.map(d => {
                                return [d.created_date.split("T")[0].split("-").slice(1).reverse().join("/"), d[type]]
                            }),
                        })
                    }

                    setTotal(total)
                    setStats(last_x_days)
                    setFetching(false)
                }
            })
            .catch(e => {
                if (process.env.NODE_ENV !== "production") {
                    console.error(e)
                }
            })
    }, [dataProvider, companyId, hideInternalUsers, numberOfDays])

    if (fetching) {
        return <CircularProgress />
    }

    return (
        <>
            <Typography component="h1" variant="h5" align="center" gutterBottom>
                {total} Created media <i>(Last {numberOfDays} days)</i>
            </Typography>
            <ColumnChart data={stats} stacked colors={["#25a93c", "#1aa2d5", "#623aaf", "#ffcb77", "#fe6d73"]} />
        </>
    )
}

export default MediaCreatedByDay
