import React from "react"
import { Datagrid, EditButton, Filter, ImageField, List, SelectInput, TextField } from "react-admin"

const CarouselFilter = props => (
    <Filter {...props}>
        <SelectInput
            source="type"
            choices={[
                { id: "DYNAMIC_IMAGES", name: "Dynamic images" },
                { id: "DASHBOARD", name: "Dashboard" },
                { id: "DASHBOARD_TRIAL", name: "Dashboard trial" },
                { id: "PUBLIC_TEMPLATES", name: "Public templates" },
                { id: "AUTOMATE", name: "Automate" },
            ]}
            alwaysOn
        />
    </Filter>
)

const CarouselList = props => (
    <List {...props} perPage={10} exporter={false} filters={<CarouselFilter />}>
        <Datagrid rowClick="edit">
            <TextField source="id" label="ID" />
            <ImageField source="image_url" title="Image" sortable={false} />
            <TextField source="link_url" label="Link" />
            <TextField source="type" label="Type" />
            <EditButton />
        </Datagrid>
    </List>
)

export default CarouselList
