import { fetchUtils } from "react-admin"
import jsonServerProvider from "ra-data-json-server"
import { auth0 } from "./authProvider"

const httpClient = async (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: "application/json" })
    }

    const token = await auth0.getTokenSilently()
    options.headers.set("Authorization", `Bearer ${token}`)

    return fetchUtils.fetchJson(url, options)
}

const convertFileToBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file.rawFile)

        reader.onload = () => resolve(reader.result)
        reader.onerror = reject
    })

export const providerUrl = window.REACT_APP_INTERNAL_API + "/internal"

const initialJsonProvider = jsonServerProvider(providerUrl, httpClient)

const modifiedJsonProvider = {
    ...initialJsonProvider,
    create: (resource, params) => {
        if (resource === "carousels") {
            return convertFileToBase64(params.data.image_url_data).then(base64Picture => {
                return initialJsonProvider.create(resource, {
                    ...params,
                    data: {
                        ...params.data,
                        image_url_data: base64Picture,
                    },
                })
            })
        }

        if (resource === "fonts") {
            if (params.data.files && params.data.files.length) {
                const fontFiles = params.data.files

                return Promise.all(fontFiles.map(convertFileToBase64))
                    .then(base64Pictures =>
                        base64Pictures.map((picture64, index) => ({
                            src: picture64,
                            title: `${fontFiles[index].title}`,
                        }))
                    )
                    .then(transformedFiles => {
                        return initialJsonProvider.create(resource, {
                            ...params,
                            data: {
                                ...params.data,
                                files: [...transformedFiles],
                            },
                        })
                    })
            }
        }

        if (resource === "public_templates") {
            if (params.data.variants && params.data.variants.length) {
                const files = params.data.variants

                return Promise.all(files.map(convertFileToBase64))
                    .then(base64Pictures =>
                        base64Pictures.map(picture64 => ({
                            src: picture64,
                        }))
                    )
                    .then(transformedFiles => {
                        return initialJsonProvider.create(resource, {
                            ...params,
                            data: {
                                ...params.data,
                                variants: [...transformedFiles],
                            },
                        })
                    })
            }
        }

        return initialJsonProvider.create(resource, params)
    },
    get: resource => {
        return httpClient(providerUrl + resource).then(({ json }) => ({
            data: json,
        }))
    },
    update: (resource, params) => {
        if (resource === "carousels") {
            if (params.data.image_url_data) {
                return convertFileToBase64(params.data.image_url_data).then(base64Picture => {
                    return initialJsonProvider.update(resource, {
                        ...params,
                        data: {
                            ...params.data,
                            image_url_data: base64Picture,
                        },
                    })
                })
            } else {
                return initialJsonProvider.update(resource, params)
            }
        }

        if (resource !== "public_templates" || !params.data.variants) {
            return initialJsonProvider.update(resource, params)
        }

        const newPictures = params.data.variants.filter(p => p.rawFile instanceof File)
        const formerPictures = params.data.variants.filter(p => !(p.rawFile instanceof File))

        return Promise.all(newPictures.map(convertFileToBase64))
            .then(base64Pictures =>
                base64Pictures.map(picture64 => ({
                    src: picture64,
                }))
            )
            .then(transformedNewPictures => {
                return initialJsonProvider.update(resource, {
                    ...params,
                    data: {
                        ...params.data,
                        variants: [...transformedNewPictures, ...formerPictures],
                    },
                })
            })
    },
}

export default modifiedJsonProvider
