import React from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import { Typography } from "@mui/material"
import moment from "moment"
import { useRecordContext } from "react-admin"

const TimestampField = ({ source }) => {
    const record = useRecordContext()
    if (!record) {
        return null
    }

    const value = get(record, source)

    if (value == null) {
        return null
    }

    const formattedDate = moment(value * 1000, "x").format("YYYY-MM-DD HH:mm")

    return (
        <Typography component="span" variant="caption" style={{ fontStyle: "italic" }}>
            {formattedDate}
        </Typography>
    )
}

TimestampField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
}

export default TimestampField
