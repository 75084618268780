import React, { useEffect } from "react"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { Button, NumberInput, required, SaveButton, SimpleForm, Toolbar, useDataProvider, useNotify, useRefresh } from "react-admin"
import { Typography } from "@mui/material"

const AddConsumedCreditsPopIn = ({ consumedCredits, creditsLimitation, companyName }) => {
    const [open, setOpen] = React.useState(false)
    const [isLoading, setIsLoadIng] = React.useState(false)
    const [remainingCredits, setRemainingCredits] = React.useState(consumedCredits)
    const notify = useNotify()
    const refresh = useRefresh()
    const dataProvider = useDataProvider()

    useEffect(() => {
        setRemainingCredits(consumedCredits)
    }, [consumedCredits])

    const postSave = data => {
        if (data["added_credits"] + consumedCredits > creditsLimitation && creditsLimitation !== -1) {
            notify(`You can't add ${data["added_credits"]} credits. It exceeds the credits limit(${creditsLimitation})`, "warning")
            return
        }

        setIsLoadIng(true)
        dataProvider
            .create("company/" + data["id"] + "/add-credits", {
                data: {
                    company_uuid: data["uuid"],
                    added_credits: data["added_credits"],
                },
            })
            .then(() => {
                handleClose()
                refresh()
                notify("Credits added")
            })
            .catch(e => {
                notify(`Error during credits addition: ${e.message}`, "warning")
            })
            .finally(() => {
                setIsLoadIng(false)
            })
    }

    const handleClose = () => {
        setOpen(false)
    }

    const PostEditToolbar = props => (
        <Toolbar {...props}>
            <Button label="Cancel" onClick={handleClose} color="primary" />
            <SaveButton sx={{ ml: "30px" }} alwaysEnable />
        </Toolbar>
    )

    return (
        <>
            <Button
                label="Add consumed credits"
                variant="contained"
                onClick={() => {
                    setOpen(true)
                }}
            />
            <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle>Add consumed credits from company {companyName}</DialogTitle>
                <DialogContent>
                    <SimpleForm onSubmit={postSave} toolbar={<PostEditToolbar />}>
                        <Typography>
                            Current consumed credits:{" "}
                            <b>
                                {consumedCredits} / {creditsLimitation !== -1 ? <>{creditsLimitation}</> : <>unlimited</>}
                            </b>
                        </Typography>
                        <Typography>
                            Credits consumed once added:{" "}
                            <b>
                                {remainingCredits} / {creditsLimitation !== -1 ? <>{creditsLimitation}</> : <>unlimited</>}
                            </b>
                        </Typography>
                        <NumberInput
                            min={1}
                            fullWidth
                            validate={[required()]}
                            label="Number of credits to add"
                            source="added_credits"
                            disabled={isLoading}
                            onChange={e => {
                                setRemainingCredits(consumedCredits + parseInt(e.target.value))
                            }}
                        />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default AddConsumedCreditsPopIn
