import React, { useEffect, useState } from "react"
import { Grid, Typography } from "@mui/material"
import { useDataProvider } from "react-admin"
import { ColumnChart, PieChart } from "react-chartkick"
import "chartkick/chart.js"

const AllTimeCreativeAutomationStats = ({ companyId = null, hideInternalUsers = true, numberOfDays = 31 }) => {
    const [stats, setStats] = useState({
        total_templates: 0,
        total_api_calls: 0,
        split_by_referrer: [],
        last_x_days: [],
    })

    const dataProvider = useDataProvider()

    useEffect(() => {
        dataProvider
            .get(
                "/stats/all_time_creative_automation?hide_internal_users=" +
                    hideInternalUsers +
                    "&number_of_days=" +
                    numberOfDays +
                    (companyId ? "&company_id=" + companyId : "")
            )
            .then(results => {
                if (results) {
                    const rawStats = results.data

                    rawStats.split_by_referrer = rawStats.split_by_referrer.map(s => {
                        return [s.referer_name, s.total]
                    })

                    const total = results.data.last_x_days
                        .map(d => {
                            return (
                                d.api +
                                d.funnel +
                                d.airtable +
                                d.integromat +
                                d.template_page +
                                d.wordpress +
                                d.zapier +
                                d["images-api"] +
                                d.forms +
                                d["single-form"]
                            )
                        })
                        .reduce((a, b) => a + b, 0)

                    const last_x_days = []

                    for (const type of [
                        "api",
                        "funnel",
                        "airtable",
                        "integromat",
                        "template_page",
                        "wordpress",
                        "zapier",
                        "images-api",
                        "forms",
                        "single-form",
                    ]) {
                        const totalType = results.data.last_x_days.map(d => d[type]).reduce((a, b) => a + b, 0)
                        last_x_days.push({
                            name: totalType + " " + type + " (" + Math.round((totalType / total) * 10000) / 100 + "%)",
                            data: results.data.last_x_days.map(d => {
                                return [d.created_date.split("T")[0].split("-").slice(1).reverse().join("/"), d[type]]
                            }),
                        })
                    }

                    rawStats["last_x_days"] = last_x_days

                    setStats(rawStats)
                }
            })
            .catch(e => {
                if (process.env.NODE_ENV !== "production") {
                    console.error(e)
                }
            })
    }, [dataProvider, companyId, hideInternalUsers, numberOfDays])

    return (
        <>
            <Typography component="h1" variant="h6" align="left" gutterBottom>
                All time creative automation stats
            </Typography>
            <br />
            <Grid container>
                <Grid item xs={4}>
                    <Typography variant="body1" component="h6" gutterBottom>
                        Total created templates: <b>{stats.total_templates}</b>
                    </Typography>
                    <Typography variant="body1" component="h6" gutterBottom>
                        Total successful API Calls: <b>{stats.total_api_calls}</b>
                    </Typography>
                    <br />
                    <br />
                    <Grid container alignItems="center" justify="center">
                        <Grid item xs={5}>
                            {stats.split_by_referrer
                                .sort((a, b) => (a[1] > b[1] ? -1 : 1))
                                .map((s, index) => (
                                    <Typography component="p" variant="body1" key={"referer-" + index}>
                                        {s[0]}: <b>{s[1]}</b>
                                    </Typography>
                                ))}
                        </Grid>
                        <Grid item>
                            <PieChart
                                data={stats.split_by_referrer}
                                donut
                                legend={false}
                                colors={["#17c3b2", "#227c9d", "#fef9ef", "#ffcb77", "#fe6d73"]}
                                width="200px"
                                height="200px"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body1" component="h6" gutterBottom align="center">
                        Last {numberOfDays} days split by referer name
                    </Typography>
                    <ColumnChart data={stats.last_x_days} stacked />
                </Grid>
            </Grid>
        </>
    )
}

export default AllTimeCreativeAutomationStats
