import { Login } from "react-admin"
import LoginForm from "../components/LoginForm"

const LoginPage = props => (
    <Login backgroundImage="none" style={{ backgroundColor: "#EAEAEA" }} {...props}>
        <LoginForm />
    </Login>
)

export default LoginPage
