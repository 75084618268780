import React from "react"
import { Show, SimpleShowLayout } from "react-admin"
import SemrushBannerCardField from "../../components/SemrushBannerCardField"

export const SemrushBannerShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <SemrushBannerCardField display="raw" />
        </SimpleShowLayout>
    </Show>
)
