import React from "react"
import get from "lodash/get"
import { withStyles } from "@mui/styles"
import { Typography } from "@mui/material"
import { useRecordContext } from "react-admin"

const styles = {
    clusterHealthArea: {
        width: "540px",
        "&:after": {
            clear: "left",
            content: '""',
            display: "block",
        },
    },
    clusterArea: {
        width: "300px",
        float: "left",
        height: "12px",
        backgroundColor: "#FFCF32",
        borderRadius: "2px",
        position: "relative",
        overflow: "hidden",
        "& > div": {
            position: "absolute",
            height: "12px",
        },
    },
    clusterDetailsArea: {
        width: "200px",
        float: "left",
        marginLeft: "20px",
    },
    cancelledPart: {
        backgroundColor: "#FF8140",
    },
    completedPart: {
        backgroundColor: "#BDD69A",
    },
    failedPart: {
        backgroundColor: "red",
    },
    otherPart: {
        backgroundColor: "#FFCF32",
    },
    clusterPartDetail: {
        width: "40px",
        float: "left",
        "& div": {
            position: "absolute",
            width: "12px",
            height: "12px",
            borderRadius: "2px",
        },
        "& span": {
            position: "relative",
            fontSize: "0.8em",
            left: "15px",
            bottom: "4px",
        },
        "&:after": {
            clear: "left",
            content: '""',
            display: "block",
        },
    },
}

const TemplateFunnelProgressField = ({ total, completed, failed, cancelled, classes = {} }) => {
    const record = useRecordContext()
    if (!record) {
        return null
    }

    const totalValue = get(record, total)

    if (totalValue == null) {
        return null
    }

    const completedValue = parseInt(get(record, completed, 0))
    const failedValue = parseInt(get(record, failed, 0))
    const cancelledValue = parseInt(get(record, cancelled, 0))

    const completedPercentage = (completedValue / totalValue) * 100
    const failedPercentage = (failedValue / totalValue) * 100
    const cancelledPercentage = (cancelledValue / totalValue) * 100

    return (
        <div className={classes.clusterHealthArea}>
            <div className={classes.clusterArea}>
                <div
                    className={classes.completedPart}
                    style={{
                        width: completedPercentage + "%",
                        left: 0,
                    }}
                />
                <div
                    className={classes.failedPart}
                    style={{
                        width: failedPercentage + "%",
                        left: completedPercentage + "%",
                    }}
                />
                <div
                    className={classes.cancelledPart}
                    style={{
                        width: cancelledPercentage + "%",
                        left: completedPercentage + failedPercentage + "%",
                    }}
                />
            </div>
            <div className={classes.clusterDetailsArea}>
                <div className={classes.clusterPartDetail}>
                    <div className={classes.completedPart}></div>
                    <Typography component="span" variant="caption">
                        {completedValue}
                    </Typography>
                </div>
                <div className={classes.clusterPartDetail}>
                    <div className={classes.failedPart}></div>
                    <Typography component="span" variant="caption">
                        {failedValue}
                    </Typography>
                </div>
                <div className={classes.clusterPartDetail}>
                    <div className={classes.cancelledPart}></div>
                    <Typography component="span" variant="caption">
                        {cancelledValue}
                    </Typography>
                </div>
                <div className={classes.clusterPartDetail}>
                    <div className={classes.otherPart}></div>
                    <Typography component="span" variant="caption">
                        {totalValue - (completedValue + failedValue + cancelledValue)}
                    </Typography>
                </div>
            </div>
        </div>
    )
}

export default withStyles(styles)(TemplateFunnelProgressField)
