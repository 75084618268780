import React from "react"
import {
    BooleanInput,
    ChipField,
    Datagrid,
    Filter,
    FunctionField,
    List,
    NumberInput,
    ReferenceField,
    SelectInput,
    TextField,
    TextInput,
    useRecordContext,
} from "react-admin"
import { Box, Typography } from "@mui/material"
import moment from "moment"
import CreatePublicTemplatesButton from "../components/Template/CreatePublicTemplatesButton"

const TemplateFilter = props => {
    return (
        <Filter {...props}>
            <NumberInput label="Company ID" source="company_id" alwaysOn />
            <TextInput alwaysOn label="Search by name" source="name" />
            <TextInput alwaysOn label="Search by UUID" source="uuid" />
            <TextInput label="Search by ID" source="id" />
            <SelectInput
                source="template_type"
                choices={[
                    { id: "animated", name: "Animated" },
                    { id: "static", name: "Static" },
                    { id: "printer", name: "Print" },
                ]}
                alwaysOn
            />
            <BooleanInput source="hide_internal_users" label="Hide internal users" alwaysOn />
        </Filter>
    )
}

const TemplatesBulkActionButtons = props => (
    <>{"filterValues" in props && props["filterValues"].company_id === 1 && <CreatePublicTemplatesButton {...props} />}</>
)

const ThumbnailPreview = () => {
    const record = useRecordContext()
    return (
        <>
            {"url" in record && (
                <>
                    {"animated_url" in record && record["animated_url"] ? (
                        <Box
                            component="video"
                            src={record.animated_url}
                            alt="Thumbnail Video"
                            controls
                            disableRemotePlayback
                            poster={record["url"]}
                            sx={{
                                maxWidth: "300px",
                                maxHeight: "300px",
                                objectFit: "contain",
                            }}
                        />
                    ) : (
                        <Box
                            component="img"
                            src={record.url}
                            alt="Banner"
                            sx={{
                                maxWidth: "300px",
                                maxHeight: "300px",
                                objectFit: "contain",
                            }}
                        />
                    )}
                </>
            )}
        </>
    )
}

export const TemplateList = props => (
    <List
        filters={<TemplateFilter />}
        perPage={50}
        sort={{ field: "id", order: "DESC" }}
        filterDefaultValues={{ hide_internal_users: true }}
        {...props}
        exporter={false}>
        <Datagrid rowClick="show" bulkActionButtons={<TemplatesBulkActionButtons />}>
            <TextField source="id" label="Template ID" />
            <ThumbnailPreview title="Preview" sortable={false} />
            <TextField source="name" label="Name" sortable={false} />
            <ChipField source="type" label="Template type" sortable={false} />
            <ReferenceField source="company_id" reference="companies" link="show" sortable={false}>
                <TextField source="name" />
            </ReferenceField>
            <FunctionField
                label="Details"
                source="details"
                sortable={false}
                render={record => {
                    return (
                        <ul>
                            <li>
                                <Typography component="p" variant="caption">
                                    Company Template UUID:&nbsp;<b>{record.company_template_uuid}</b>
                                </Typography>
                            </li>
                            <li>
                                <Typography component="p" variant="caption">
                                    Template type:&nbsp;<b>{record.type}</b>
                                </Typography>
                            </li>
                            <li>
                                <Typography component="p" variant="caption">
                                    Current version:&nbsp;<b>{record.version}</b>
                                    <br />
                                    <i>({record.template_uuid})</i>
                                </Typography>
                            </li>
                            <li>
                                <Typography component="p" variant="caption">
                                    Updated at: <b>{moment(record.created_at_ts * 1000, "x").format("YYYY-MM-DD HH:mm")}</b>
                                </Typography>
                            </li>
                        </ul>
                    )
                }}
            />
        </Datagrid>
    </List>
)
