import React from "react"
import {
    BooleanField,
    BooleanInput,
    ChipField,
    Datagrid,
    Filter,
    FunctionField,
    List,
    NumberInput,
    ReferenceField,
    TextField,
    useRecordContext,
} from "react-admin"
import ArtTrackIcon from "@mui/icons-material/ArtTrack"
import moment from "moment"
import { Link } from "react-router-dom"
import { Button } from "@mui/material"

const TemplateFormFilter = props => {
    return (
        <Filter {...props}>
            <NumberInput label="Company ID" source="company_id" alwaysOn />
            <BooleanInput source="hide_internal_users" label="Hide internal users" defaultValue="true" />
        </Filter>
    )
}

const BannersShowButton = () => {
    const record = useRecordContext()
    return (
        <Button
            component={Link}
            size="small"
            color="primary"
            variant="contained"
            startIcon={<ArtTrackIcon />}
            to={{
                pathname: "/banner_builder",
                search:
                    "filter=" +
                    JSON.stringify({
                        template_ids: record.template_id,
                    }),
            }}>
            Banners
        </Button>
    )
}

export const TemplateFormList = props => (
    <List
        {...props}
        filters={<TemplateFormFilter />}
        exporter={false}
        sort={{ field: "id", order: "DESC" }}
        filterDefaultValues={{ hide_internal_users: true }}>
        <Datagrid bulkActionButtons={false}>
            <FunctionField label="ID" sortBy="id" render={record => `#${record.id}`} />
            <TextField source="template_form_uuid" sortable={false} />
            <ReferenceField label="Template" source="template_id" reference="templates" link="show" sortable={false}>
                <ChipField source="name" />
            </ReferenceField>
            <ReferenceField label="Company" source="company_id" reference="companies" link="show" sortable={false}>
                <ChipField source="name" />
            </ReferenceField>
            <BooleanField source="is_active" />
            <FunctionField label="Created date" sortBy="created_at_ts" render={record => moment(record.created_at_ts * 1000, "x").format("DD/MM/YYYY")} />
            <BannersShowButton />
        </Datagrid>
    </List>
)
