import React from "react"
import { Show, SimpleShowLayout, useRecordContext } from "react-admin"
import BannerBuilderCardField from "../components/BannerBuilderCardField"

const SimpleBanner = () => {
    const record = useRecordContext()

    if (!record) {
        return <></>
    }

    return <BannerBuilderCardField display="raw" record={record} />
}

export const BannerBuilderShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <SimpleBanner />
        </SimpleShowLayout>
    </Show>
)
