import React from "react"
import get from "lodash/get"
import { Chip } from "@mui/material"
import { useRecordContext } from "react-admin"

const ChipStatus = ({ source = "", chipLabel = null, createdIsInProgress = false }) => {
    const record = useRecordContext()

    if (!chipLabel) {
        chipLabel = get(record, source)

        if (chipLabel == null) {
            return null
        }
    }

    let color = "#FFF"
    let backgroundColor = "#C7CDD9"

    if (createdIsInProgress && chipLabel === "CREATED") {
        backgroundColor = "#FFCF32"
        chipLabel = "IN PROGRESS"
    } else {
        switch (chipLabel) {
            case "DISABLED":
            case "PAYMENT_FAILED":
            case "REFUSED":
            case "DELETED":
            case "UNVERIFIED":
            case "TRIAL_ENDED":
            case "NOT_ALLOWED": // bandwidth
                backgroundColor = "#F05648"
                break

            case "CANCELLED":
                backgroundColor = "#FF8140"
                break

            case "TRIAL":
            case "IN_PROGRESS":
                backgroundColor = "#1935A6"
                break

            case "ACTIVE":
            case "ACCEPTED":
            case "CREATED":
            case "UNLIMITED":
            case "LIFETIME_DEAL":
            case "COMPLETE":
            case "ALLOWED": // bandwidth
                backgroundColor = "#00B359"
                break

            case "FREE":
                backgroundColor = "#999999"
                break

            default:
        }
    }

    return (
        <Chip
            label={chipLabel}
            style={{
                backgroundColor: backgroundColor,
                color: color,
            }}
        />
    )
}

export default ChipStatus
