import React from "react"
import { Datagrid, Filter, List, TextField, TextInput, useRecordContext } from "react-admin"
import { Button } from "@mui/material"
import TimestampField from "../../components/TimestampField"
import { Link } from "react-router-dom"

const ShowBannersOfFunnel = () => {
    const record = useRecordContext()

    if (!record) {
        return <></>
    }

    const filter = {
        funnel_id: record.id,
    }

    return (
        <Button
            component={Link}
            size="small"
            color="primary"
            to={{
                pathname: "/semrush_banners",
                search: "filter=" + JSON.stringify(filter),
            }}>
            {" "}
            Show
        </Button>
    )
}

const FunnelFilter = props => (
    <Filter {...props}>
        <TextInput source="user_id" label="User ID" />
    </Filter>
)

const UserField = () => {
    const record = useRecordContext()

    if (!record || !("user_id" in record)) {
        return null
    }

    return (
        <>
            <Button
                component={Link}
                size="small"
                color="primary"
                target="_blank"
                to={{
                    pathname: "/semrush_user",
                    search:
                        "filter=" +
                        JSON.stringify({
                            id: record.user_id,
                        }),
                }}>
                {record.user_id}
            </Button>
        </>
    )
}

export const SemrushFunnelList = props => (
    <List {...props} filters={<FunnelFilter />} exporter={false} sort={{ field: "id", order: "DESC" }}>
        <Datagrid bulkActionButtons={false}>
            <TextField source="id" label="ID" />
            <TimestampField source="created_at_ts" label="Date" />
            <UserField label="User ID" />
            <TextField source="generated_banners" label="Banners" />
            <ShowBannersOfFunnel />
        </Datagrid>
    </List>
)
