import React, { useEffect } from "react"
import { useLogin } from "react-admin"
import { Button } from "@mui/material"

const LoginForm = () => {
    const login = useLogin()

    useEffect(() => {
        const location = window.location.href
        const url = new URL(window.location.href)
        const { searchParams } = url
        const code = searchParams.get("code")
        const state = searchParams.get("state")

        // If code is present, we came back from the provider
        if (code && state) {
            login({ url: location })
        }
    }, [login])

    const handleLogin = () => {
        login() // Do not provide code, just trigger the redirection
    }

    return (
        <Button
            variant="contained"
            type="submit"
            color="primary"
            onClick={handleLogin}
            style={{
                width: "calc(100% - 20px)",
                margin: "10px",
            }}>
            Login
        </Button>
    )
}

export default LoginForm
