import React from "react"
import { BooleanInput, Datagrid, Filter, List, ReferenceField, TextField, TextInput, useRecordContext } from "react-admin"
import { Button } from "@mui/material"
import { Link as RouterLink } from "react-router-dom"

import TimestampField from "../components/TimestampField"
import TemplateFunnelProgress from "../components/TemplateFunnelProgress"
import TemplateFunnelErrors from "../components/TemplateFunnelErrors"
import ChipStatus from "../components/ChipStatus"

const ShowBannersOfFunnel = () => {
    const record = useRecordContext()
    const filter = {
        funnel_processing_id: record.id,
    }

    if (record.completed_banners === 0) {
        return <></>
    }

    return (
        <Button
            component={RouterLink}
            size="small"
            color="primary"
            to={{
                pathname: "/banner_builder",
                search: "filter=" + JSON.stringify(filter),
            }}>
            Show Banners
        </Button>
    )
}

const FunnelFilter = props => (
    <Filter {...props}>
        <TextInput label="Company" source="company_id" alwaysOn />
        <BooleanInput source="hide_internal_users" label="Hide internal users" defaultValue={true} />
    </Filter>
)

export const TemplateFunnelList = props => (
    <List {...props} filters={<FunnelFilter />} exporter={false} sort={{ field: "id", order: "DESC" }} filterDefaultValues={{ hide_internal_users: true }}>
        <Datagrid rowClick="expand" expand={<TemplateFunnelErrors />} bulkActionButtons={false}>
            <TextField source="id" label="ID" />
            <TimestampField source="created_at_ts" label="Date" sortable={false} />
            <ReferenceField source="company_id" reference="companies" link="show" sortable={false}>
                <TextField source="name" />
            </ReferenceField>
            <TextField source="funnel_name" label="Funnel Name" sortable={false} />
            <ChipStatus source="state" createdIsInProgress={true} sortable={false} />
            <TemplateFunnelProgress
                sortable={false}
                label="Funnel progress"
                total="total_banners"
                completed="completed_banners"
                failed="failed_banners"
                cancelled="cancelled_banners"
            />
            <ShowBannersOfFunnel />
        </Datagrid>
    </List>
)
