import { Auth0Client } from "@auth0/auth0-spa-js"

export const auth0 = new Auth0Client({
    domain: window.REACT_APP_AUTH0_DOMAIN,
    clientId: window.REACT_APP_AUTH0_CLIENT_ID,
    cacheLocation: "localstorage",
    authorizationParams: {
        redirect_uri: window.REACT_APP_AUTH0_REDIRECT_URI,
        audience: window.REACT_APP_AUTH0_AUDIENCE,
    },
})

const authProvider = {
    // called when the user attempts to log in
    login: ({ url }) => {
        if (url) {
            return auth0.handleRedirectCallback(url)
        }

        return auth0.loginWithRedirect()
    },
    // called when the user clicks on the logout button
    logout: () => {
        return auth0.isAuthenticated().then(isAuthenticated => {
            if (isAuthenticated) {
                // need to check for this as react-admin calls logout in case checkAuth failed
                return auth0.logout({
                    redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
                    federated: true, // have to be enabled to invalidate refresh token
                })
            }

            return Promise.resolve()
        })
    },
    // called when the API returns an error
    checkError: ({ status }) => {
        if (status === 401) {
            return Promise.reject()
        }

        return Promise.resolve()
    },
    checkAuth: async () => {
        const isAuthenticated = await auth0.isAuthenticated()

        if (isAuthenticated) {
            return Promise.resolve()
        }

        return auth0.getTokenSilently()
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => {
        return Promise.resolve()
    },
}

export default authProvider
