import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@mui/styles"
import { Button, Card, CardContent, Typography } from "@mui/material"
import moment from "moment"
import { Link } from "react-router-dom"
import clsx from "clsx"
import { Document, Page, pdfjs } from "react-pdf"

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.js`

const styles = {
    bannerCard: {
        margin: "0.5em",
        display: "inline-block",
        verticalAlign: "top",
    },
    normalBannerCard: {
        width: 300,
        "& svg, & img": {
            maxWidth: "300px",
            maxHeight: "300px",
        },
    },
    bannerContent: {
        position: "relative",
        "& > img": {
            width: "100%",
            objectFit: "contain",
        },
    },
    html5Overlay: {
        position: "absolute",
        width: "100%",
        height: "100%",
        display: "flex",
        backgroundColor: "rgba(0,0,0,0.1)",
        top: "0",
        left: "0",
        alignItems: "center",
        justifyContent: "center",
    },
}

const BannerBuilderCardField = ({ record = {}, classes = {}, display = "small" }) => {
    if (!record) {
        return null
    }

    const style = {}
    if (record.width && record.height) {
        style.width = record.width + "px"
        style.height = record.height + "px"
    }

    return (
        <Card className={clsx(classes.bannerCard, display !== "raw" && classes.normalBannerCard)}>
            <div className={classes.bannerContent} style={{ ...style }}>
                {record.url.endsWith(".html") ? (
                    <>
                        <iframe
                            src={record.url}
                            style={{ border: 0, height: "100%", width: "100%", overflow: "hidden", "& body": { width: "300px" } }}
                            title={record.id}
                        />
                        {display !== "raw" && (
                            <div className={classes.html5Overlay}>
                                <Button
                                    component={Link}
                                    size="small"
                                    color="primary"
                                    className={classes.smallButton}
                                    to={{
                                        pathname: "/banner_builder/" + record.id + "/show",
                                    }}>
                                    HTML5
                                </Button>
                            </div>
                        )}
                    </>
                ) : record.url.endsWith(".mp4") ? (
                    <video src={record.url + "#t=0.5"} controls alt="Banner" hight="300px" width="300px" type="video/mp4" />
                ) : record.url.endsWith(".pdf") ? (
                    <Document
                        file={record.url}
                        error={
                            <Typography variant="h4" sx={{ textAlign: "center" }}>
                                PDF preview is not available
                                <br />
                                You can download it by clicking on the button bellow
                            </Typography>
                        }>
                        <Page pageNumber={1} className="pdf-preview" width={500} />
                    </Document>
                ) : (
                    <img src={record.url} alt="Banner" />
                )}
            </div>
            <CardContent>
                <Typography component="p" variant="caption">
                    Banner ID:{" "}
                    <b>
                        <Button
                            component={Link}
                            size="small"
                            color="primary"
                            className={classes.smallButton}
                            to={{
                                pathname: "/banner_builder/" + record.id + "/show",
                            }}>
                            #{record.id}
                        </Button>
                    </b>
                </Typography>
                <Typography component="p" variant="caption">
                    Filter by Company:&nbsp;
                    <Button
                        component={Link}
                        size="small"
                        color="primary"
                        target="_blank"
                        className={classes.smallButton}
                        to={{
                            pathname: "/banner_builder",
                            search:
                                "filter=" +
                                JSON.stringify({
                                    company_id: record.company_id,
                                }),
                        }}>
                        {record.company_id}
                    </Button>
                </Typography>
                <Typography component="p" variant="caption">
                    Created at: {moment(record.created_at_ts * 1000, "x").format("YYYY-MM-DD HH:mm")}
                </Typography>
            </CardContent>
        </Card>
    )
}

BannerBuilderCardField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
}

export default withStyles(styles)(BannerBuilderCardField)
