import React from "react"
import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, Divider, Grid, Typography } from "@mui/material"
import moment from "moment"
import { Button, useDataProvider, useNotify, useRecordContext } from "react-admin"
import { useMutation } from "react-query"

import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ChipStatus from "./ChipStatus"
import APICallsByDay from "../components/Stats/APICallsByDay"
import APICreatedTemplatesByDay from "../components/Stats/APICreatedTemplatesByDay"
import AllTimeCreativeAutomationStats from "../components/Stats/AllTimeCreativeAutomationStats"
import GeneratedBannersByMonth from "./Stats/GeneratedBannersByMonth"
import APICallsToday from "./Stats/APICallsToday"
import RemoveConsumedCreditsPopin from "./RemoveConsumedCreditsPopin"
import AddConsumedCreditsPopIn from "./AddConsumedCreditsPopin"

const StatusButton = ({ id, label, action }) => {
    const notify = useNotify()
    const dataProvider = useDataProvider()

    const { mutate, isLoading } = useMutation(
        () => {
            if (action) {
                return dataProvider.create("companies", {
                    data: {
                        company_id: id,
                    },
                })
            } else {
                return dataProvider.delete("companies", { id: id })
            }
        },
        {
            onSuccess: ({ data }) => {
                notify(action ? "A new user is created" : "Your user has been deleted")
            },
            onError: error => notify(`Error during create the user status: ${error.message}`, "warning"),
        }
    )
    return <Button label={label} onClick={mutate} disabled={isLoading} />
}

const CompanySummaryGrid = () => {
    const record = useRecordContext()

    return (
        <Grid container spacing={4}>
            <Grid item xs={4}>
                <Card>
                    <CardContent>
                        <Typography color="textSecondary" gutterBottom>
                            {record.uuid}
                        </Typography>
                        <Typography variant="h5" component="h2">
                            {record.name} ({record.id}) <ChipStatus chipLabel={record.status} />
                        </Typography>
                        <Typography color="textSecondary">Created date: {record.created_date}</Typography>
                        {(record.status === "TRIAL" || record.status === "TRIAL_ENDED") && (
                            <Typography color="textSecondary">Trial end date: {record.trial_end_date}</Typography>
                        )}
                        <Typography variant="body2" component="p">
                            <br />
                            Number of users : <strong>{record.number_of_users}</strong>
                            <br />
                            Number of API Calls : <strong>{record.number_of_api_calls}</strong>
                            <br />
                            Weekly average API Calls <small>(last 12 weeks)</small> : <strong>{record.avg_api_calls_per_week_last_12w}</strong>
                            <br />
                            Number of Created Templates : <strong>{record.number_of_templates}</strong>
                            {record.last_connection_date && (
                                <>
                                    <br />
                                    <br />
                                    Last user connection : <strong>{moment(record.last_connection_date * 1000, "x").fromNow()}</strong>
                                </>
                            )}
                            <br />
                            Credits usage:{" "}
                            <strong>
                                {record.consumed_credits} / {record.credits_limitation !== -1 ? <>{record.credits_limitation}</> : <>unlimited</>}
                            </strong>
                            <br />
                            {["ACTIVE", "LIFETIME_DEAL", "ENTERPRISE", "PARTNER", "TRIAL", "UNLIMITED"].includes(record.status) && (
                                <>
                                    Billing/Credits period: {record.period_start_at} /{" "}
                                    {record.status === "TRIAL" ? record.trial_end_date : record.period_end_at}
                                </>
                            )}
                        </Typography>
                        <br />

                        <StatusButton id={record.id} label="Add Impersonate User" action={true} />
                        <StatusButton id={record.id} label="Delete Impersonate User" action={false} />
                        <br />
                        <br />
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <RemoveConsumedCreditsPopin
                                    companyName={record.name}
                                    consumedCredits={record.consumed_credits}
                                    creditsLimitation={record.credits_limitation}
                                />
                            </Grid>
                            <Grid item>
                                <AddConsumedCreditsPopIn
                                    companyName={record.name}
                                    consumedCredits={record.consumed_credits}
                                    creditsLimitation={record.credits_limitation}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={8}>
                <Card>
                    <CardContent>
                        <Typography color="textSecondary" gutterBottom>
                            Today stats
                        </Typography>
                        <APICallsToday companyId={record.id} hideInternalUsers={false} />
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12}>
                <Accordion TransitionProps={{ unmountOnExit: true }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h5">Creative-automation</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div style={{ width: "100%" }}>
                            <Divider />
                            <br />
                            <Grid container spacing={6}>
                                <Grid item xs={12}>
                                    <AllTimeCreativeAutomationStats companyId={record.id} hideInternalUsers={false} />
                                </Grid>
                                <Grid item xs={6}>
                                    <APICreatedTemplatesByDay companyId={record.id} hideInternalUsers={false} />
                                </Grid>
                                <Grid item xs={6}>
                                    <APICallsByDay companyId={record.id} hideInternalUsers={false} />
                                </Grid>
                                <Grid item xs={6}>
                                    <GeneratedBannersByMonth companyId={record.id} hideInternalUsers={false} />
                                </Grid>
                            </Grid>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
    )
}

export default CompanySummaryGrid
