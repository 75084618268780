import React from "react"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { Button, NumberInput, required, SimpleForm, useDataProvider, useNotify, useRefresh } from "react-admin"
import { useMutation } from "react-query"

const MoveTemplateButton = ({ templateId, targetCompanyId, handleClose }) => {
    const notify = useNotify()
    const refresh = useRefresh()
    const dataProvider = useDataProvider()

    const { mutate, isLoading } = useMutation(
        () =>
            dataProvider.create("template", {
                data: {
                    template_id: templateId,
                    target_company_id: targetCompanyId,
                },
            }),
        {
            onSuccess: ({ data }) => {
                handleClose()
                refresh()
                notify("Template " + templateId + " Moved")
            },
            onError: error => notify(`Error during the moving of the template: ${error.message}`, "warning"),
        }
    )
    return <Button label="Move Template" onClick={mutate} disabled={isLoading} />
}

const MoveTemplatePopin = ({ templateId, companyId }) => {
    const [targetCompanyId, setTargetCompanyId] = React.useState(null)
    const [open, setOpen] = React.useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            <Button label="Move Template" variant="contained" onClick={handleClickOpen} />
            <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle>Move Template to another company</DialogTitle>
                <DialogContent>
                    <SimpleForm
                        toolbar={
                            <>
                                <Button label="Cancel" onClick={handleClose} color="primary" />
                                <MoveTemplateButton companyId={companyId} templateId={templateId} targetCompanyId={targetCompanyId} handleClose={handleClose} />
                            </>
                        }>
                        <NumberInput
                            fullWidth
                            validate={[required()]}
                            label="company id"
                            source="company_id"
                            onChange={e => setTargetCompanyId(e.target.value)}
                        />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default MoveTemplatePopin
