import React from "react"
import get from "lodash/get"
import { Chip } from "@mui/material"

const ChipStatus = ({ record = {}, source = "", chipLabel = null }) => {
    if (!chipLabel) {
        chipLabel = get(record, source)

        if (chipLabel == null) {
            return null
        }
    }

    let color = "#FFF"
    let backgroundColor = "#C7CDD9"

    switch (chipLabel) {
        case "FLAGGED":
            backgroundColor = "#F05648"
            break

        case "BOOKMARKED":
            backgroundColor = "#1935A6"
            break

        case "DOWNLOADED":
            backgroundColor = "#00B359"
            break

        case "CREATED":
            backgroundColor = "#999999"
            break

        default:
    }

    return (
        <Chip
            label={chipLabel}
            style={{
                backgroundColor: backgroundColor,
                color: color,
            }}
        />
    )
}

export default ChipStatus
