import React, { useState } from "react"
import { FunctionField, Loading, useGetOne, useGetRecordId } from "react-admin"
import { Button, Box, Card, CardContent, Divider, Typography } from "@mui/material"
import MoveTemplatePopin from "../components/MoveTemplatePopin"
import { Link } from "react-router-dom"
import moment from "moment"

const SimpleTemplate = ({ recordId }) => {
    const [retry, setRetry] = useState(0)
    const { data, isLoading, error, refetch } = useGetOne("templates", { id: recordId })

    if (isLoading) {
        return <Loading />
    }
    if (error) {
        return <p>ERROR: {error.message}</p>
    }

    if (!data.formats) {
        if (retry < 3) {
            setRetry(retry => retry + 1)
            refetch()
        }
        return <></>
    }

    return (
        <Card sx={{ width: "100%" }}>
            <CardContent>
                <Typography variant="h4">{data.name}</Typography>
                <Divider sx={{ mt: "10px", mb: "10px" }} />
                <Typography component="p" variant="caption">
                    Template UUID:&nbsp;<b>{data.company_template_uuid}</b>
                </Typography>
                <Typography component="p" variant="caption">
                    Template type:&nbsp;<b>{data.type}</b>
                </Typography>
                <Typography component="p" variant="caption">
                    Current version:&nbsp;<b>{data.version}</b>
                    <br />
                    <i>({data.template_uuid})</i>
                </Typography>
                <Typography component="p" variant="caption">
                    Filter by Company:&nbsp;
                    <Button
                        component={Link}
                        size="small"
                        color="primary"
                        target="_blank"
                        to={{
                            pathname: "/templates",
                            search:
                                "filter=" +
                                JSON.stringify({
                                    company_id: data.company_id,
                                }),
                        }}>
                        {data.company_id}
                        <Typography component="span" variant="caption" style={{ textTransform: "none", fontWeight: "0.5em" }}>
                            &nbsp;({data.company_name})
                        </Typography>
                    </Button>
                </Typography>
                <Typography component="p" variant="caption">
                    Updated at: {moment(data.created_at_ts * 1000, "x").format("YYYY-MM-DD HH:mm")}
                </Typography>

                <Box sx={{ mt: "20px" }}>
                    <MoveTemplatePopin templateId={data.id} companyId={data.company_id} />
                </Box>

                <Divider sx={{ mt: "40px", mb: "20px" }} />
                <Typography variant="h4">Formats:</Typography>
                {data.formats.map(format => (
                    <Box sx={{ borderBottom: "1px solid #EAEAEA", mb: "20px" }}>
                        <Typography variant="h5">
                            {format.name}{" "}
                            <i>
                                ({format.width}x{format.height})
                            </i>
                        </Typography>
                        <Box>
                            {"animated_url" in format && format.animated_url ? (
                                <Box
                                    component="video"
                                    src={format.animated_url}
                                    alt="Thumbnail Video"
                                    controls
                                    disableRemotePlayback
                                    poster={format.url}
                                    sx={{
                                        maxWidth: "300px",
                                        maxHeight: "300px",
                                        objectFit: "contain",
                                    }}
                                />
                            ) : (
                                <img src={format.url} alt="Banner" />
                            )}
                        </Box>
                    </Box>
                ))}
            </CardContent>
        </Card>
    )
}

export const TemplateShow = props => {
    const recordId = useGetRecordId()

    if (!recordId) {
        return <></>
    }

    return <SimpleTemplate recordId={recordId} />
}
