import React, { useEffect, useState } from "react"
import { Link, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import { Link as RouterLink } from "react-router-dom"
import { useDataProvider } from "react-admin"
import "chartkick/chart.js"

const LeastConnectedCompanies = ({ title, hideInternalUsers = true, numberOfDays = 31 }) => {
    const [companies, setCompanies] = useState([])

    const dataProvider = useDataProvider()

    useEffect(() => {
        dataProvider
            .get("/stats/least_connected_companies?hide_internal_users=" + hideInternalUsers + "&number_of_days=" + numberOfDays)
            .then(results => {
                if (results) {
                    setCompanies(results.data)
                }
            })
            .catch(e => {
                if (process.env.NODE_ENV !== "production") {
                    console.error(e)
                }
            })
    }, [dataProvider, hideInternalUsers, numberOfDays])

    return (
        <>
            <Typography component="h1" variant="h6" align="center" gutterBottom>
                {title}
            </Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ backgroundColor: "#EAEAEA" }}>Company</TableCell>
                        <TableCell align="right" style={{ backgroundColor: "#EAEAEA" }}>
                            User
                        </TableCell>
                        <TableCell align="right" style={{ backgroundColor: "#EAEAEA" }}>
                            Last Connection
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {companies.map(c => (
                        <TableRow key={c.company_id}>
                            <TableCell component="th" scope="row">
                                <Link
                                    component={RouterLink}
                                    variant="text"
                                    to={{
                                        pathname: "/companies/" + c.company_id + "/show",
                                    }}>
                                    {c.company_name} <i>({c.company_status})</i>
                                </Link>
                            </TableCell>
                            <TableCell align="right">
                                <b>{c.user_name}</b>
                            </TableCell>
                            <TableCell align="right">
                                <b>{c.last_connection_date}</b>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    )
}

export default LeastConnectedCompanies
