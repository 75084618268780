import React from "react"
import { Loading, useGetOne, useRecordContext } from "react-admin"
import { Box, Typography } from "@mui/material"
import ChipStatus from "./ChipStatus"
import { LineChart } from "react-chartkick"

const BandwidthUsageGrid = ({ companyId }) => {
    const { data, isLoading, error } = useGetOne("company_bandwidth", { id: companyId })

    if (isLoading) {
        return <Loading />
    }

    if (error) {
        return (
            <Box
                sx={{
                    padding: "15px 25px 25px 25px",
                }}>
                Error while loading data ({error.message})
            </Box>
        )
    }

    return (
        <Box
            sx={{
                padding: "15px 25px 25px 25px",
            }}>
            {data && (
                <>
                    <Box sx={{ mb: "15px" }}>
                        <ChipStatus chipLabel={data.access_allowed ? "ALLOWED" : "NOT_ALLOWED"} />
                    </Box>
                    <Box sx={{ mb: "15px" }}>
                        <Typography>
                            Bandwidth usage for the current period :&nbsp;
                            <strong>{(data.current_period_consumed_kb / 1000000).toFixed(3)}</strong>
                            &nbsp;/&nbsp;
                            <strong>{data.current_period_allowed_kb / 1000000}</strong>
                            &nbsp;Gb
                        </Typography>
                    </Box>
                    <LineChart data={data.last_days_aggregated} suffix=" Mb" />
                </>
            )}
        </Box>
    )
}

const BandwidthUsage = () => {
    const record = useRecordContext()

    if (!record) {
        return <></>
    }

    return <BandwidthUsageGrid companyId={record.id} />
}

export default BandwidthUsage
