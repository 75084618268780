import React, { useEffect, useState } from "react"
import { Link, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import { Link as RouterLink } from "react-router-dom"
import { useDataProvider } from "react-admin"
import "chartkick/chart.js"

const MostActiveCompanies = ({ title, hideInternalUsers = true, numberOfDays = 31, status = [], criteria = "api_calls", onlyToday = false }) => {
    const [companies, setCompanies] = useState([])

    const dataProvider = useDataProvider()

    useEffect(() => {
        dataProvider
            .get(
                "/stats/most_active_companies?hide_internal_users=" +
                    hideInternalUsers +
                    "&number_of_days=" +
                    numberOfDays +
                    "&criteria=" +
                    criteria +
                    "&only_today=" +
                    onlyToday +
                    (status.length > 0 ? "&status=" + status.join(",") : "")
            )
            .then(results => {
                if (results) {
                    setCompanies(results.data)
                }
            })
            .catch(e => {
                if (process.env.NODE_ENV !== "production") {
                    console.error(e)
                }
            })
    }, [dataProvider, hideInternalUsers, numberOfDays])

    return (
        <>
            <Typography component="h1" variant="h6" align="center" gutterBottom>
                {title}
            </Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ backgroundColor: "#EAEAEA" }}>Company</TableCell>
                        <TableCell align="right" style={{ backgroundColor: "#EAEAEA" }}>
                            {criteria === "templates" ? "Templates" : "Total Calls"}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {companies.map(c => (
                        <TableRow key={c.company_id}>
                            <TableCell component="th" scope="row">
                                <Link
                                    component={RouterLink}
                                    variant="text"
                                    to={{
                                        pathname: "/companies/" + c.company_id + "/show",
                                    }}>
                                    {c.company_name} <i>({c.company_status})</i>
                                </Link>
                            </TableCell>
                            <TableCell align="right">
                                <b>{c.total}</b>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    )
}

export default MostActiveCompanies
