import React, { useEffect, useState } from "react"
import { Button, Card, CardContent, Grid, Typography } from "@mui/material"
import { useDataProvider } from "react-admin"
import { Link } from "react-router-dom"
import { PieChart } from "react-chartkick"
import "chartkick/chart.js"

import { withStyles } from "@mui/styles"

const styles = {
    list: {
        listStyleType: "none",
        paddingLeft: 0,
    },
    bubble: {
        display: "inline-block",
        height: "10px",
        width: "10px",
        borderRadius: "50%",
    },
    button: {
        padding: "3px 0",
        minWidth: 0,
    },
}

const GlobalStats = ({ classes = {} }) => {
    const [stats, setStats] = useState({
        total_companies: 0,
        total_paid_companies: 0,
        total_lifetime_deal_companies: 0,
        total_trial_companies: 0,
        total_paused_companies: 0,
        total_free_companies: 0,
    })

    const dataProvider = useDataProvider()

    useEffect(() => {
        dataProvider
            .get("/stats")
            .then(results => {
                if (results) {
                    setStats(results.data)
                }
            })
            .catch(e => {
                if (process.env.NODE_ENV !== "production") {
                    console.error(e)
                }
            })
    }, [dataProvider])

    const percentage = (part, total) => {
        return Math.round((part / total) * 10000) / 100
    }

    return (
        <>
            <Card>
                <CardContent>
                    <Typography component="h1" variant="h5" align="center" gutterBottom>
                        All time company stats
                    </Typography>
                    <Grid container alignItems="center">
                        <Grid item xs={6}>
                            <Typography variant="h6" component="h6" gutterBottom align="center">
                                Total companies: <b>{stats.total_companies}</b>
                            </Typography>
                            <Typography variant="body2" component="ul" className={classes.list}>
                                <li>
                                    <span className={classes.bubble} style={{ backgroundColor: "#17c3b2" }}></span> Paid:&nbsp;
                                    <Button
                                        component={Link}
                                        variant="text"
                                        className={classes.button}
                                        to={{
                                            pathname: "/companies",
                                            search:
                                                "filter=" +
                                                JSON.stringify({
                                                    status: "ACTIVE",
                                                }),
                                        }}>
                                        <b>{stats.total_paid_companies}</b> ({percentage(stats.total_paid_companies, stats.total_companies)}%)
                                    </Button>
                                </li>
                                <li>
                                    <span className={classes.bubble} style={{ backgroundColor: "#227c9d" }}></span> LTD:&nbsp;
                                    <Button
                                        component={Link}
                                        variant="text"
                                        className={classes.button}
                                        to={{
                                            pathname: "/companies",
                                            search:
                                                "filter=" +
                                                JSON.stringify({
                                                    status: "LIFETIME_DEAL",
                                                }),
                                        }}>
                                        <b>{stats.total_lifetime_deal_companies}</b> ({percentage(stats.total_lifetime_deal_companies, stats.total_companies)}%)
                                    </Button>
                                </li>
                                <li>
                                    <span className={classes.bubble} style={{ backgroundColor: "#fef9ef" }}></span> Free:&nbsp;
                                    <Button
                                        component={Link}
                                        variant="text"
                                        className={classes.button}
                                        to={{
                                            pathname: "/companies",
                                            search:
                                                "filter=" +
                                                JSON.stringify({
                                                    status: "FREE",
                                                }),
                                        }}>
                                        <b>{stats.total_free_companies}</b> ({percentage(stats.total_free_companies, stats.total_companies)}%)
                                    </Button>
                                </li>
                                <li>
                                    <span className={classes.bubble} style={{ backgroundColor: "#ffcb77" }}></span> Trial:&nbsp;
                                    <Button
                                        component={Link}
                                        variant="text"
                                        className={classes.button}
                                        to={{
                                            pathname: "/companies",
                                            search:
                                                "filter=" +
                                                JSON.stringify({
                                                    status: "TRIAL",
                                                }),
                                        }}>
                                        <b>{stats.total_trial_companies}</b> ({percentage(stats.total_trial_companies, stats.total_companies)}%)
                                    </Button>
                                </li>
                                <li>
                                    <span className={classes.bubble} style={{ backgroundColor: "#fe6d73" }}></span> Paused:&nbsp;
                                    <Button
                                        component={Link}
                                        variant="text"
                                        className={classes.button}
                                        to={{
                                            pathname: "/companies",
                                            search:
                                                "filter=" +
                                                JSON.stringify({
                                                    status: "PAUSED",
                                                }),
                                        }}>
                                        <b>{stats.total_paused_companies}</b> ({percentage(stats.total_paused_companies, stats.total_companies)}
                                        %)
                                    </Button>
                                </li>
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <PieChart
                                data={[
                                    ["Paid Recurring", stats.total_paid_companies],
                                    ["LTD", stats.total_lifetime_deal_companies],
                                    ["Free", stats.total_free_companies],
                                    ["Trial", stats.total_trial_companies],
                                    ["Paused", stats.total_paused_companies],
                                ]}
                                donut
                                legend={false}
                                colors={["#17c3b2", "#227c9d", "#fef9ef", "#ffcb77", "#fe6d73"]}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    )
}

export default withStyles(styles)(GlobalStats)
